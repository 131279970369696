<template>
  <timeline></timeline>
</template>

<script>
import timeline from "@/components/editor/timeline";

export default {
  name: "footer1",
  components: {
    timeline
  }
}
</script>

<style scoped>

</style>
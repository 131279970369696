<template>
  <div class="cont11">

    <div class="text1">
      <div class=" animate__animated animate__fadeIn animate__delay-1s">11111</div>
      <div class=" animate__animated animate__fadeIn animate__delay-2s">222</div>
      <div class=" animate__animated animate__backInDown animate__delay-3s">333</div>
      <div class=" animate__animated animate__backInDown animate__delay-4s">444</div>
    </div>


  </div>
</template>

<script>
export default {
  name: "effect1"
};
</script>

<style>
.cont11 {padding: 100px;}

.text1 {

  border-radius: 10px;
  line-height: 150%;
  font-size: 24px;
}

.text1 :first-child {
  color: red;
  background-color: aqua;
  margin-bottom: 15px;
  border-radius: 10px;
}

.text1 :not(:first-child) {
  color: green;
  background-color: #171a16;
  margin-bottom: 15px;
  border-radius: 10px;
}
</style>
<template>
  <engine :id="$route.params.id"></engine>
</template>

<script>
import engine from "@/components/player/engine";
export default {
  name: "player",
  components: {
    engine,
  },
};
</script>

<style scoped></style>

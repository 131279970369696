<template>
  <el-tabs :tab-position="tabPosition">
    <el-tab-pane label="场景" style="height: 840px; overflow-y: scroll">
      <draggable
        v-model="editor.meta.sences"
        group="g1"
        @start="drag = true"
        @end="drag = false"
        item-key="id"
      >
        <template #item="{ element, index }">
          <div
            style="
              width: 180px;
              height: 50px;
              background-color: rgb(221, 226, 226);
              margin-bottom: 10px;
            "
            :class="element.id === editor.currentSence.id ? 'actived' : ''"
          >
            <div
              style="width: 130px; height: 50px; display: inline-flex; align-items: stretch"
              @click.prevent="select(element)"
            >
              <span style="font-size: 16px">{{ index + 1 }}</span>
              <Icon size="20" v-if="haveVideo(element)"><Video24Regular /></Icon>
              <Icon size="20" v-if="haveAudio(element)"><HeadphonesSoundWave24Filled /></Icon>
              <Icon size="20" v-if="haveImage(element)"><Image24Regular /></Icon>
              <Icon size="20" v-if="element.note"><ClipboardTextLtr24Regular /></Icon>

              <!-- {{ haveAudio(element) ? "音" : "" }}
              {{ haveVideo(element) ? "视" : "" }}
              {{ haveImage(element) ? "图" : "" }}
              {{ element.note ? "文" : "" }} -->
            </div>

            <div style="width: 45px; height: 50px; display: inline-flex; align-items: stretch">
              <Icon size="20" style="padding-top: 15px" @click.prevent="selectCopy(element, index)"
                ><Copy24Regular
              /></Icon>
              <Icon size="20" style="padding-top: 15px" @click.prevent="selectDel(index)"
                ><Delete24Regular
              /></Icon>
            </div>

            <!-- <el-button
              style="width: 20px"
              :size="'mini'"
              @click.prevent="selectCopy(element, index)"
              >C</el-button
            >
            <el-button style="width: 20px" :size="'mini'" @click.prevent="selectDel(index)"
              >X</el-button
            > -->
          </div>
        </template>
      </draggable>
    </el-tab-pane>
    <el-tab-pane label="元素">
      <draggable
        v-model="editor.currentSence.sprites"
        group="people"
        @start="drag = true"
        @end="drag = false"
        item-key="id"
      >
        <template #item="{ element }">
          <div
            class="sortsprites"
            @click="select2(element)"
            :class="element.id === editor.currentSprite?.id ? 'sprite_actived' : ''"
          >
            <!-- <img :src="element?.preview" style="height: 50px; width: auto" /> -->
            <span v-if="element.type == 'video'">视频</span>
            <span v-if="element.type == 'audio'">音频</span>
            <span v-if="element.type == 'text'">文本</span>
            <span v-if="element.type == 'image'">图片</span>
            <span v-if="element.type == 'lottie'">角色</span>
          </div>
        </template>
      </draggable>
    </el-tab-pane>
    <el-tab-pane label="角色">
      <lottieResource></lottieResource>
    </el-tab-pane>
    <el-tab-pane label="背景">
      <bgResource></bgResource>
    </el-tab-pane>
    <el-tab-pane label="素材">
      <resResource></resResource>
    </el-tab-pane>
    <el-tab-pane label="文字">
      <textResource></textResource>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
// import domtoimage from 'dom-to-image';
import ObjectID from "bson-objectid";
import { ElMessage } from "element-plus";
import _ from "lodash";

import lottieResource from "./resource/lottieResource";
import bgResource from "./resource/bgResource";
import resResource from "./resource/resResource";
import textResource from "./resource/textResource";

import { Icon } from "@vicons/utils";
import {
  Video24Regular,
  HeadphonesSoundWave24Filled,
  ClipboardTextLtr24Regular,
  Image24Regular,
  Copy24Regular,
  Delete24Regular,
} from "@vicons/fluent";

export default {
  name: "left",
  components: {
    draggable,
    Icon,
    Video24Regular,
    HeadphonesSoundWave24Filled,
    ClipboardTextLtr24Regular,
    Image24Regular,
    Copy24Regular,
    Delete24Regular,
    lottieResource,
    bgResource,
    resResource,
    textResource,
    //
    // Money16Regular,
  },
  data() {
    return {
      drag: false,
      tabPosition: "left",
    };
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
  },
  methods: {
    select(item) {
      this.editor.currentSenceId = item.id;
      this.editor.currentSence = item;
      this.editor.currentSprite = {};
      // setTimeout(()=>{
      //   // html2canvas(document.querySelector("#huabu1")).then(canvas => {
      //   //   document.body.appendChild(canvas);
      //   // });
      //
      //   var node = document.querySelector('#huabu1');
      //   var node2 = document.querySelector( '#tumb' + item.id);
      //
      //   domtoimage.toPng(node)
      //     .then(function (dataUrl) {
      //       //var img = new Image();
      //       //img.src = dataUrl;
      //       //document.body.appendChild(img);
      //
      //       console.log(dataUrl);
      //       console.log(node2);
      //       node2.style.backgroundImage = dataUrl;
      //     })
      //     .catch(function (error) {
      //       console.error('oops, something went wrong!', error);
      //     });
      //
      // },0);
    },
    select2(item) {
      item.active = true;
      this.editor.currentSprite = item;
    },
    showTitle(item) {
      if (item.type == "text") {
        return item.text;
      } else {
        return item.name;
      }
    },
    selectCopy(element, index) {
      let json = JSON.stringify(element);
      localStorage.setItem("copySence", json);

      let newSence = JSON.parse(json);
      newSence.id = ObjectID().toHexString();
      newSence.sprites.forEach((x) => {
        x.id = ObjectID().toHexString();
      });

      this.editor.meta.sences.splice(index, 0, newSence);
      this.editor.currentSence = newSence;
    },
    selectDel(index) {
      var total = this.editor.meta.sences.length;

      if (total != 1) {
        this.editor.meta.sences.splice(index, 1);

        if (index < total - 1) {
          this.editor.currentSence = this.editor.meta.sences[index];
        } else {
          this.editor.currentSence = this.editor.meta.sences[index - 1];
        }
      } else {
        ElMessage({ message: "必须保留1个场景！", duration: 600 });
      }
    },
    haveAudio(element) {
      var arr = _.filter(element.sprites, function (o) {
        return o.type == "audio";
      });

      return arr.length > 0 ? true : false;
    },
    haveVideo(element) {
      var arr = _.filter(element.sprites, function (o) {
        return o.type == "video";
      });

      return arr.length > 0 ? true : false;
    },
    haveImage(element) {
      var arr = _.filter(element.sprites, function (o) {
        return o.type == "image";
      });

      return arr.length > 0 ? true : false;
    },
  },
};
</script>

<style scoped>
.actived {
  border: 2px #f00 solid;
}
.sortsprites {
  border: cadetblue 1px solid;
  padding: 15px;
}

.sprite_actived {
  border: #ff0000 1px solid;
  margin-bottom: 5px;
}
</style>

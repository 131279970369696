<template>
  <div
    id="huabu1"
    style="
      width: 1280px;
      height: 720px;
      border: aquamarine 1px solid;
      position: absolute;
      background-size: cover;
      z-index: 88;
      left: 0px;
      top: 0px;
    "
  >
    <!-- <div v-if="editor.currentSence.bgEffect === 'videobg1'">
      <video
        :id="'sbg' + editor.currentSence.id"
        src="/images/bgnew01.mp4"
        style="width: 1280px; height: 720px"
        preload="auto"
        autoplay
      ></video>
    </div>

    <div v-if="editor.currentSence.bgEffect === 'videobg2'">
      <video
        :id="'sbg' + editor.currentSence.id"
        src="/images/bgnew02.mp4"
        style="width: 1280px; height: 720px"
        preload="auto"
        autoplay
      ></video>
    </div>

    <div v-if="editor.currentSence.bgEffect === 'videobg3'">
      <video
        :id="'sbg' + editor.currentSence.id"
        src="/images/bgnew03.mp4"
        style="width: 1280px; height: 720px"
        preload="auto"
        autoplay
      ></video>
    </div> -->

    <div
      v-if="editor.meta.backgroundImage"
      style="position: absolute; z-index: 0; left: 0px; top: 0px"
    >
      <img
        v-if="bgTypeCheck(editor.meta.backgroundImage) === 'jpg'"
        style="width: 1280px; height: 720px"
        :src="editor.meta.backgroundImage"
      />
      <video
        v-if="bgTypeCheck(editor.meta.backgroundImage) === 'mp4'"
        style="width: 1280px; height: 720px"
        :src="editor.meta.backgroundImage"
        autoplay
        loop
        muted
      ></video>
    </div>

    <div
      v-if="editor.currentSence.backgroundImage"
      style="position: absolute; z-index: 1; left: 0px; top: 0px"
    >
      <img
        v-if="bgTypeCheck(editor.currentSence.backgroundImage) === 'jpg'"
        style="width: 1280px; height: 720px"
        :src="editor.currentSence.backgroundImage"
      />
      <video
        v-if="bgTypeCheck(editor.currentSence.backgroundImage) === 'mp4'"
        style="width: 1280px; height: 720px"
        :src="editor.currentSence.backgroundImage"
        autoplay
        loop
        muted
      ></video>
    </div>

    <Vue3DraggableResizable
      v-for="(item, index) of editor.currentSence.sprites"
      :key="item.id"
      :initW="item.width"
      :initH="item.height"
      v-model:x="item.left"
      v-model:y="item.top"
      v-model:w="item.width"
      v-model:h="item.height"
      v-model:active="item.active"
      :draggable="true"
      :resizable="true"
      :lockAspectRatio="false"
      @activated="activatedHandle(item)"
      @deactivated="print('deactivated')"
      @drag-start="print('drag-start')"
      @resize-start="print('resize-start')"
      @dragging="print('dragging')"
      @resizing="print('resizing')"
      @drag-end="print('drag-end')"
      @resize-end="resizeEndHandle"
      :style="'z-index: ' + (100 - index)"
      :class="item.active ? 'animate__animated animate__fadeIn' : ''"
    >
      <textShow
        :id="'text-ep-' + item.id"
        v-if="item.type == 'text'"
        :item="item"
        :style1="computeStyle(item, index)"
      ></textShow>

      <img v-if="item.type == 'image'" :src="item.preview" :style="computeStyle(item, index)" />

      <!--        <video v-if="item.type == 'video'" :src="item.preview" :style="computeStyle(item,index)" preload controls></video>-->

      <!--        <audio v-if="item.type == 'audio'" :src="item.preview" :style="computeStyle(item,index)" preload controls></audio>-->

      <flash v-if="item.type == 'flash'" :item="item" :style="computeStyle(item, index)"></flash>

      <audioShow
        v-if="item.type == 'audio'"
        :item="item"
        :style="computeStyle(item, index)"
      ></audioShow>

      <videoShow
        v-if="item.type == 'video' && !item.green"
        :item="item"
        :style="computeStyle(item, index)"
      ></videoShow>
      <videoGreen
        v-if="item.type == 'video' && item.green"
        :item="item"
        :style="computeStyle(item, index)"
      ></videoGreen>

      <lottieShow
        v-if="item.type == 'lottie'"
        :item="item"
        :style1="computeStyle(item, index)"
      ></lottieShow>
    </Vue3DraggableResizable>

    <div
      id="mask1"
      style="
        width: 1280px;
        height: 720px;
        position: absolute;
        background-size: cover;
        outline: solid 1000px rgba(97, 97, 97, 0.205);
        pointer-events: none;
      "
    ></div>

    <div
      v-if="editor.currentSence.note"
      id="zimu____1"
      style="
        position: absolute;
        width: 1280px;
        height: 60px;
        left: 0px;
        top: 660px;
        text-align: center;
        vertical-align: middle;
        pointer-events: none;
        z-index: 8888;
        font-family: '黑体';
        font-size: 32px;
        color: #fff;
        -webkit-text-fill-color: #fff;
        -webkit-text-stroke: 1.1px #000;
        /* border: 1px solid #f00; */
      "
    >
      {{ editor.currentSence.note }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Vue3DraggableResizable from "vue3-draggable-resizable";
//需引入默认样式
import "vue3-draggable-resizable/dist/Vue3DraggableResizable.css";
import videoShow from "@/components/editor/videoShow";
import videoGreen from "@/components/editor/videoGreen";
import audioShow from "@/components/editor/audioShow";
import textShow from "@/components/editor/textShow";
import flash from "@/components/editor/flash";
import lodash from "lodash";
import lottieShow from "./lottieShow.vue";

export default {
  name: "drawpanel",
  components: {
    Vue3DraggableResizable,
    videoShow,
    videoGreen,
    audioShow,
    textShow,
    flash,
    lottieShow,
  },
  data() {
    return {
      x: 500,
      y: 500,
      h: 100,
      w: 100,
      active: false,
    };
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
  },
  methods: {
    print(val) {
      console.log(val);
    },
    activatedHandle(item) {
      this.editor.currentSprite = item;
      //console.log(item.text);
    },
    resizeEndHandle() {
      if (this.editor.currentSprite.type == "text") {
        var dom1 = document.querySelector(
          "#text-ep-" + this.editor.currentSprite.id
        ).firstElementChild;
        // var height = dom1.style.height;
        console.log(dom1.offsetWidth);
        console.log(dom1.offsetHeight);

        this.editor.currentSprite.width = dom1.offsetWidth;
        this.editor.currentSprite.height = dom1.offsetHeight;
      }
    },
    computeStyle(item, index) {
      let str = "";
      str += "color: " + item.color + ";";
      str += "font-size: " + item.fontSize + "px;";
      str += "font-family: '微软雅黑';";
      str += "line-height: 150%;";
      //str += "text-indent: 2em;";
      //str += "border: cadetblue 1px dashed;";
      str += "left: " + item.left + "px;";
      str += "top: " + item.top + "px;";
      str += "width: " + item.width + "px;";
      str += "height: " + item.height + "px;";
      str += "z-index: " + (100 - index) + ";";
      str += "word-break: break-all;";
      str += "word-wrap: break-word;";
      return str;
    },
    bgTypeCheck(bgUrl) {
      if (lodash.includes(bgUrl, "mp4")) {
        return "mp4";
      }

      return "jpg";
    },
  },
};
</script>

<style scoped>
.xxxxx {
  border: rgb(95, 168, 170) 1px dashed;
}
#huabu1 {
  /* background-image: url("/images/bg01.png"); */
  background-size: cover;
}
</style>

<template>
  <el-tabs type="card" v-model="activeName" @tab-click="handleClick" stretch="true">
    <el-tab-pane label="参数" name="first" style="padding-left: 5px; padding-right: 5px">
      <commonProp></commonProp>
    </el-tab-pane>
    <el-tab-pane label="当前" name="second">
      <sceneConfig></sceneConfig>
    </el-tab-pane>
    <el-tab-pane label="全局" name="three">
      <bgConfig></bgConfig>
    </el-tab-pane>
    <el-tab-pane label="工具" name="four">
      <toolConfig></toolConfig>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import commonProp from "@/components/editor/commonProp";
import bgConfig from "@/components/editor/bgConfig";
import sceneConfig from "@/components/editor/sceneConfig";
import toolConfig from "@/components/editor/toolConfig";

export default {
  name: "right",
  components: {
    commonProp,
    bgConfig,
    sceneConfig,
    toolConfig,
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<template>
  <el-container>
    <el-header style="background-color: #000; padding: 10px; z-index: 2900"
      ><header1></header1
    ></el-header>
    <el-container>
      <el-aside
        width="300px"
        style="
          z-index: 2500;
          padding-top: 10px;
          background-color: #f0f0f0;
          position: absolute;
          width: 300px;
          height: 100%;
          left: 0px;
          top: 60px;
        "
        ><left1></left1
      ></el-aside>
      <el-container style="z-index: 1000">
        <el-main
          style="
            z-index: 1000;
            height: 720px;
            padding-top: 5px;
            position: absolute;
            width: 1400px;
            height: 100%;
            left: 295px;
            top: 60px;
          "
        >
          <content1></content1>
          <button1></button1>
        </el-main>
        <el-footer
          style="
            z-index: 2500;
            background-color: #fff;
            height: 100px;
            overflow: hidden;
            position: absolute;
            width: 1350px;
            height: 120px;
            left: 300px;
            top: 840px;
          "
          ><footer1></footer1
        ></el-footer>
      </el-container>
      <el-aside
        style="
          z-index: 2500;
          padding-top: 20px;
          padding-left: 20px;
          background-color: #fff;
          position: absolute;
          width: 310px;
          height: 100%;
          right: 0px;
          top: 60px;
        "
        ><right1></right1
      ></el-aside>
    </el-container>
  </el-container>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { ElMessage } from "element-plus";

import header1 from "@/components/editor/header1";
import left1 from "@/components/editor/left1";
import content1 from "@/components/editor/content1";
import right1 from "@/components/editor/right1";
import footer1 from "@/components/editor/footer1";
import button1 from "@/components/editor/button1";

import ObjectID from "bson-objectid";
import _ from "lodash";

export default {
  name: "editor",
  components: {
    header1,
    left1,
    content1,
    right1,
    footer1,
    button1,
  },
  data() {
    return {
      mobanVisible: false,
      jueseVisible: false,
      vmbanVisible: false,
      bgList: [
        { type: "image", url: "/images/bg04.png" },
        { type: "image", url: "/images/bg01.png" },
        { type: "image", url: "/images/bg03.png" },
        { type: "image", url: "/images/bg02.png" },
        { type: "video", url: "/resource/bg/bg11.mp4" },
        { type: "video", url: "/resource/bg/bg12.mp4" },
        { type: "video", url: "/resource/bg/bg14.mp4" },
        { type: "video", url: "/resource/bg/bg15.mp4" },
        { type: "video", url: "/resource/bg/bg16.mp4" },
        { type: "video", url: "/resource/bg/bg17.mp4" },
        { type: "video", url: "/resource/bg/bg18.mp4" },
        { type: "video", url: "/resource/bg/bg20.mp4" },
      ],
    };
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
  },
  mounted() {
    this.lodaData();
  },
  methods: {
    lodaData() {
      let _id = this.$route.params.id;
      //console.log(_id);

      let that = this;

      axios.get("/bg_list.json?" + _.now()).then(function (response) {
        console.log(response.data);
        let _data = response.data;
        that.editor.bgList = _data;
      });

      axios.get("/api/project/getsingle/" + _id).then(function (response) {
        //console.log(response.data.result);
        let _data = response.data.result;
        that.editor.project = _data;

        if (_data.meta != "") {
          let _meta = JSON.parse(_data.meta);
          that.editor.meta = that.reduMeta(_meta);
        } else {
          that.editor.meta = { sences: [] };
        }

        console.log(_data);

        that.editor.currentSence = that.editor.meta.sences[0];
        //this.editor.currentSenceId = this.editor.currentSence.id;
      });
    },
    reduMeta(_meta) {
      _meta.sences.forEach((x) => {
        //
        if (x.bgEffect === undefined) {
          x.bgEffect = "";
        }

        x.sprites = _.remove(x.sprites, function (n) {
          return n != null;
        });

        x.sprites.forEach((y) => {
          //console.log(y);
          if (y.start === undefined) {
            y.start = 0;
          }
          if (y.clip === undefined) {
            y.clip = 0;
          }
          if (y.duration === undefined) {
            y.duration = 5;
          }
          if (y.muted === undefined) {
            y.muted = false;
          }
          if (y.type === "audio") {
            y.width = 300;
            y.height = 100;
            y.left = -40;
            y.top = 0;
          }
          if (y.textEffect === undefined) {
            y.textEffect = "none";
          }

          if (typeof y.fontSize === "string") {
            y.fontSize = parseInt(y.fontSize);
          }
        });
      });

      return _meta;
    },
    msglv() {
      ElMessage({ message: "请选中绿幕视频后，点击右侧的绿幕抠像按钮。", type: "success" });
    },
    msgzm() {
      ElMessage({ message: "请看右侧属性区，将多段文字放入后选择效果。", type: "success" });
    },
    msgdx() {
      ElMessage({ message: "请看右侧属性区，切换全局背景后后选择动效。", type: "success" });
    },
    msgpy() {
      ElMessage({ message: "语音服务器通信中，速度较慢。。。", type: "warnning" });
    },
    addSprite1(url) {
      var new1 = {
        name: "svg1",
        type: "image",
        path: url,
        preview: url,
        width: 640,
        height: 360,
        size: 1111,
        duration: 3,
        id: ObjectID().toHexString(),
        left: 0,
        top: 0,
        clip: 0,
        muted: false,
        active: false,
      };

      this.editor.currentSence.sprites.push(new1);
      //this.movePastePosition();
      this.jueseVisible = false;
    },
    addSprite2(url) {
      var new1 = {
        name: "svg2",
        type: "image",
        path: url,
        preview: url,
        width: 1280,
        height: 720,
        size: 1111,
        duration: 3,
        id: ObjectID().toHexString(),
        left: 0,
        top: 0,
        clip: 0,
        muted: false,
        active: false,
      };

      this.editor.currentSence.sprites.push(new1);
      //this.movePastePosition();
      this.mobanVisible = false;
    },
    vmbSelect(url) {
      this.editor.meta.backgroundImage = url;
      this.vmbanVisible = false;
    },
  },
};
</script>

<style scoped></style>

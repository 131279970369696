<template>
  <div
    class="sceneMain"
    :class="visible ? 'sceneShow' : 'sceneHide'"
    :style="'z-index: ' + (100 - sceneIndex)"
    ref="scene1"
  >
    <!-- <div v-if="scene.bgEffect === 'videobg1'">
      <video
        :id="'sbg' + scene.id"
        src="/images/bgnew01.mp4"
        style="width: 1280px; height: 720px"
        preload="auto"
      ></video>
    </div>

    <div v-if="scene.bgEffect === 'videobg2'">
      <video
        :id="'sbg' + scene.id"
        src="/images/bgnew02.mp4"
        style="width: 1280px; height: 720px"
        preload="auto"
      ></video>
    </div>

    <div v-if="scene.bgEffect === 'videobg3'">
      <video
        :id="'sbg' + scene.id"
        src="/images/bgnew03.mp4"
        style="width: 1280px; height: 720px"
        preload="auto"
      ></video>
    </div> -->

    <div v-if="bgUrl" style="position: absolute">
      <img v-if="bgUrl" style="width: 1280px; height: 720px" :src="bgUrl" />
    </div>

    <div v-for="(sprite, index) of scene.sprites" :key="sprite.id + 'sceneList'">
      <playText
        v-if="sprite.type == 'text'"
        :sceneIndex="sceneIndex"
        :spriteIndex="index"
        :sprite="sprite"
      ></playText>
      <playImage
        v-if="sprite.type == 'image'"
        :sceneIndex="sceneIndex"
        :spriteIndex="index"
        :sprite="sprite"
      ></playImage>
      <playVideo
        v-if="sprite.type == 'video' && sprite.green == false"
        :sceneIndex="sceneIndex"
        :spriteIndex="index"
        :sprite="sprite"
      ></playVideo>
      <playVideoGreen
        v-if="sprite.type == 'video' && sprite.green == true"
        :sceneIndex="sceneIndex"
        :spriteIndex="index"
        :sprite="sprite"
      ></playVideoGreen>
      <playAudio
        v-if="sprite.type == 'audio'"
        :sceneIndex="sceneIndex"
        :spriteIndex="index"
        :sprite="sprite"
      ></playAudio>
      <playFlash
        v-if="sprite.type == 'flash'"
        :sceneIndex="sceneIndex"
        :spriteIndex="index"
        :sprite="sprite"
      ></playFlash>
      <playLottie
        v-if="sprite.type == 'lottie'"
        :sceneIndex="sceneIndex"
        :spriteIndex="index"
        :sprite="sprite"
      ></playLottie>
    </div>

    <div
      v-if="editor.meta.scenes[sceneIndex].note"
      id="zimu____1"
      style="
        position: absolute;
        width: 1280px;
        height: 60px;
        left: 0px;
        top: 660px;
        text-align: center;
        vertical-align: middle;
        pointer-events: none;
        z-index: 8888;
        font-family: '黑体';
        font-size: 32px;
        color: #fff;
        -webkit-text-fill-color: #fff;
        -webkit-text-stroke: 1.1px #000;
      "
    >
      {{ editor.meta.scenes[sceneIndex].note }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import lodash from "lodash";

import playText from "@/components/player/playText";
import playImage from "@/components/player/playImage";
import playVideo from "@/components/player/playVideo";
import playVideoGreen from "@/components/player/playVideoGreen";
import playAudio from "@/components/player/playAudio";
import playFlash from "@/components/player/playFlash";
import playLottie from "@/components/player/playLottie";

export default {
  name: "playScene",
  components: {
    playText,
    playImage,
    playVideo,
    playVideoGreen,
    playAudio,
    playFlash,
    playLottie,
  },
  data() {
    return {
      visible: false,
      rafId: 0,
      startTime: 0,
      endTime: 0,
      effectOut: false,
      bgUrl: "",
    };
  },
  props: {
    sceneIndex: Number,
    scene: Object,
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    let that = this;
    console.log(
      ">>>>>>  scene mounted--- index:" + this.sceneIndex + "---duration:" + this.scene.duration
    );

    let globalBg = this.editor.meta.backgroundImage;
    let currentBg = this.editor.meta.scenes[this.sceneIndex].backgroundImage;

    if (currentBg) {
      this.bgUrl = currentBg;
    } else {
      this.bgUrl = globalBg;
    }
  },
  watch: {
    "scene.runStatus": {
      handler: function (newVal, oldVal) {
        console.log(
          ">> watch scene.runStatus---sceneIndex:" +
            this.sceneIndex +
            "---newVal:" +
            newVal +
            "---oldVal:" +
            oldVal
        );

        if (newVal == 2) {
          this.visible = true;

          //启动精灵
          this.editor.meta.scenes[this.sceneIndex].sprites.forEach((x) => {
            x.runStatus = 2;
          });

          //
          // try {
          //   console.log(this.scene.bgEffect);
          //   let sbg = document.getElementById("sbg" + this.scene.id);
          //   console.log(sbg);
          //   sbg.play();
          // } catch (error) {
          //   console.log(this.scene.bgEffect);
          //   console.log(error);
          // }

          //启动场景
          this.start();

          //全局启动
          if (this.sceneIndex == 0) {
            this.globalStart();
          }

          //下一场景预加载
          let endIndex = this.editor.meta.scenes.length - 1;
          if (this.sceneIndex != endIndex) {
            //预加载下一场景
            this.editor.meta.scenes[this.sceneIndex + 1].runStatus = 1;
            //预加载下一场景的精灵
            this.editor.meta.scenes[this.sceneIndex + 1].sprites.forEach((x) => {
              x.runStatus = 1;
            });
          }

          //启动全局bga循环播放
          if (this.sceneIndex === 1) {
            let bga = document.getElementById("bg_handler_audio_01");

            //日照特别设置
            // if (this.$route.params.id > 200 && this.$route.params.id < 422) {
            //   bga.volume = 0.05;
            // }
            bga.volume = 0.2;
            bga.loop = true;
            bga.play();
          }

          //启动全局bga停止
          if (this.sceneIndex === endIndex) {
            let bga = document.getElementById("bg_handler_audio_01");
            bga.pause();
          }
        }

        if (newVal == 4) {
          //this.visible = false;
          //结束本次场景
          this.stop();

          //下一场景启动
          let endIndex = this.editor.meta.scenes.length - 1;
          if (this.sceneIndex != endIndex) {
            this.editor.meta.scenes[this.sceneIndex + 1].runStatus = 2;

            // this.editor.meta.scenes[this.sceneIndex + 1].sprites.forEach(x => {
            //   x.runStatus = 1;
            // });
          }

          //全局停止
          if (this.sceneIndex == endIndex) {
            this.globalEnd();
          }
        }

        if (newVal == 9) {
          this.visible = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    start() {
      this.beginTime = window.performance.now();
      this.endTime = this.beginTime + this.scene.duration * 1000;
      //raf循环
      this.rafFun();
    },
    stop() {
      cancelAnimationFrame(this.rafId);
      console.log(this.rafId);

      //3秒后移除dom
      let that = this;
      setTimeout(() => {
        that.editor.meta.scenes[this.sceneIndex].runStatus = 9;
      }, 100);
    },
    rafFun() {
      if (window.performance.now() > this.endTime - 500 && !this.effectOut) {
        try {
          //退出动效
          if (this.editor.meta.exitEffect === "fadeOut") {
            this.$refs.scene1.classList.add(
              "animate__animated",
              "animate__fadeOut",
              "animate__faster"
            );
          }

          if (this.editor.meta.exitEffect === "fadeOutLeft") {
            this.$refs.scene1.classList.add(
              "animate__animated",
              "animate__fadeOutLeft",
              "animate__faster"
            );
          }
        } catch (ex) {
          console.log(ex.message);
        }

        this.effectOut = true;
      }

      if (window.performance.now() > this.endTime) {
        this.editor.meta.scenes[this.sceneIndex].runStatus = 4;
      } else {
        this.rafId = requestAnimationFrame(this.rafFun);
        //console.log("rafId: " + this.rafId);
      }
    },
    globalStart() {
      console.log("globalStart");
      let that = this;

      //调用录制开始
      setTimeout(() => {
        // eslint-disable-next-line no-unused-vars
        let videoName1 = that.editor.meta.name + ".mp4";
        // eslint-disable-next-line no-unused-vars
        // let videoName2 =
        //   "D:\\www\\PolarisServer\\wwwroot\\resource\\publish\\" + that.$route.params.id + ".mp4";

        // eslint-disable-next-line no-undef
        callbackObj.record("start");
        //console.log(window.callbackObj);
      }, 0);
    },
    globalEnd() {
      console.log("globalEnd");

      //调用录制结束
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        callbackObj.record("end");
        //console.log(window.callbackObj);
      }, 0);
    },
    bgTypeCheck(bgUrl) {
      if (lodash.includes(bgUrl, "mp4")) {
        return "mp4";
      }

      return "jpg";
    },
  },
};
</script>

<style scoped>
.sceneMain {
  width: 1280px;
  height: 720px;
  position: absolute;
  /*background-color: #4cbf6e;*/
}

.sceneHide {
  visibility: hidden;
}

.sceneShow {
  visibility: visible;
}
</style>

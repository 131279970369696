<template>
  <div>
    <div style="margin-bottom: 5px">
      <span>名称：</span>
      <el-input
        v-model="editor.currentSprite.name"
        placeholder="请输入内容"
        style="display: inline-flex"
      ></el-input>
    </div>

    <div style="margin-bottom: 5px; display: flex; align-items: center">
      <span style="vertical-align: middle">颜色：</span>
      <el-color-picker
        v-model="editor.currentSprite.color"
        size="mini"
        style="display: inline-flex"
      ></el-color-picker>
    </div>

    <div style="margin-bottom: 15px">
      <span>大小：</span>
      <el-input-number
        v-model="editor.currentSprite.fontSize"
        size="mini"
        :min="14"
        :max="60"
        label="字体大小"
        style="display: inline-flex"
      ></el-input-number>
    </div>

    <div style="margin-bottom: 5px">
      <el-button
        style="margin-bottom: 5px"
        class="el-button"
        size="mini"
        type="primary"
        :class="editor.currentSprite.textEffect === 'none' ? 'xxxActive' : ''"
        @click="editor.currentSprite.textEffect = 'none'"
        >无</el-button
      >

      <el-button
        style="margin-bottom: 5px"
        class="el-button"
        size="mini"
        type="success"
        :class="editor.currentSprite.textEffect === 'fadeOut' ? 'xxxActive' : ''"
        @click="editor.currentSprite.textEffect = 'fadeOut'"
        >淡入</el-button
      >
      <el-button
        style="margin-bottom: 5px"
        class="el-button"
        size="mini"
        type="success"
        :class="editor.currentSprite.textEffect === 'typeStr' ? 'xxxActive' : ''"
        @click="editor.currentSprite.textEffect = 'typeStr'"
        >打字</el-button
      >

      <!-- 
      <div style="margin-top: 20px">
        <el-button
          style="margin-bottom: 5px"
          class="el-button"
          size="mini"
          type="info"
          :class="editor.currentSence.bgEffect === 'videobg1' ? 'xxxActive' : ''"
          @click="editor.currentSence.bgEffect = 'videobg1'"
          >背景1电视</el-button
        >
        <el-button
          style="margin-bottom: 5px"
          class="el-button"
          size="mini"
          type="info"
          :class="editor.currentSence.bgEffect === 'videobg2' ? 'xxxActive' : ''"
          @click="editor.currentSence.bgEffect = 'videobg2'"
          >背景2大屏</el-button
        >
        <el-button
          style="margin-bottom: 5px"
          class="el-button"
          size="mini"
          type="info"
          :class="editor.currentSence.bgEffect === 'videobg3' ? 'xxxActive' : ''"
          @click="editor.currentSence.bgEffect = 'videobg3'"
          >背景3星星</el-button
        >
      </div> -->

      <!-- <el-button
        style="margin-bottom: 5px"
        class="el-button"
        size="mini"
        type="success"
        plain
        :class="editor.currentSprite.textEffect === 'list1' ? 'el-button--primary' : ''"
        @click="editor.currentSprite.textEffect = 'list1'"
        >智能列表1</el-button
      >
      <el-button
        style="margin-bottom: 5px"
        class="el-button"
        size="mini"
        type="success"
        plain
        :class="editor.currentSprite.textEffect === 'list2' ? 'el-button--primary' : ''"
        @click="editor.currentSprite.textEffect = 'list2'"
        >智能列表2</el-button
      >
      <el-button
        style="margin-bottom: 5px"
        class="el-button"
        size="mini"
        type="success"
        plain
        :class="editor.currentSprite.textEffect === 'content1' ? 'el-button--primary' : ''"
        @click="editor.currentSprite.textEffect = 'content1'"
        >智能内容1</el-button
      >
      <el-button
        style="margin-bottom: 5px"
        class="el-button"
        size="mini"
        type="success"
        plain
        :class="editor.currentSprite.textEffect === 'title1' ? 'el-button--primary' : ''"
        @click="editor.currentSprite.textEffect = 'title1'"
        >智能标题2</el-button
      >
      <el-button
        style="margin-bottom: 5px"
        class="el-button"
        size="mini"
        type="success"
        plain
        :class="editor.currentSprite.textEffect === 'list1' ? 'el-button--primary' : ''"
        @click="editor.currentSprite.textEffect = 'list1'"
        >智能列表2</el-button
      >
      <el-button
        style="margin-bottom: 5px"
        class="el-button"
        size="mini"
        type="success"
        plain
        :class="editor.currentSprite.textEffect === 'list2' ? 'el-button--primary' : ''"
        @click="editor.currentSprite.textEffect = 'list2'"
        >智能列表2</el-button
      >
      <el-button
        style="margin-bottom: 5px"
        class="el-button"
        size="mini"
        type="success"
        plain
        :class="editor.currentSprite.textEffect === 'content1' ? 'el-button--primary' : ''"
        @click="editor.currentSprite.textEffect = 'content1'"
        >智能内容2</el-button
      >-->
    </div>

    <div style="margin-bottom: 15px; padding-right: 15px">
      <span>文字：</span>
      <el-input
        type="textarea"
        :rows="5"
        placeholder="请输入内容"
        v-model="editor.currentSprite.text"
      >
        @change="changeText"
      </el-input>
    </div>

    <div style="margin-bottom: 5px">
      <span>抠像：</span>
      <el-switch
        v-model="editor.currentSprite.green"
        active-color="#13ce66"
        inactive-color="#ff4949"
      ></el-switch>
      &nbsp;
      <input type="color" v-model="editor.currentSprite.gColor" />
    </div>

    <div style="margin-bottom: 5px">
      <span>左点</span>
      <el-input-number
        v-model="editor.currentSprite.left"
        label="left"
        style="display: inline-flex"
      ></el-input-number>
      <el-button class="el-button--primary" @click="fuheBg">符1280x720</el-button>
    </div>
    <div style="margin-bottom: 5px">
      <span>顶点</span>
      <el-input-number
        v-model="editor.currentSprite.top"
        label="left"
        style="display: inline-flex"
      ></el-input-number>
      <el-button class="el-button--success" size="small" @click="fuheGreen1">符960x1080</el-button>
    </div>
    <div style="margin-bottom: 5px">
      <span>宽度</span>
      <el-input-number
        v-model="editor.currentSprite.width"
        label="left"
        style="display: inline-flex"
      ></el-input-number>
      <el-button class="el-button--success" size="small" @click="fuheGreen2">符1440x2160</el-button>
    </div>
    <div style="margin-bottom: 5px">
      <span>高度</span>
      <el-input-number
        v-model="editor.currentSprite.height"
        label="left"
        style="display: inline-flex"
      ></el-input-number>
      <el-button class="el-button--primary" size="small" @click="fuheGreen3">符2</el-button>
    </div>

    <div style="margin-bottom: 5px">
      <span>静音：</span>
      <el-switch
        v-model="editor.currentSprite.muted"
        active-color="#13ce66"
        inactive-color="#ff4949"
      ></el-switch>
    </div>
    <div style="margin-bottom: 5px">
      <span>剪切</span>
      <el-input-number
        :precision="2"
        :step="0.1"
        v-model="editor.currentSprite.clip"
        label="left"
        style="display: inline-flex"
      ></el-input-number>
      <el-button class="el-button--primary" @click="editor.currentSprite.start = 0">复0</el-button>
    </div>
    <div style="margin-bottom: 5px">
      <span>起始</span>
      <el-input-number
        :precision="2"
        :step="0.1"
        v-model="editor.currentSprite.start"
        label="left"
        style="display: inline-flex"
        @change="cha1"
      ></el-input-number
      ><el-button class="el-button--primary" @click="cha1">查1</el-button>
    </div>
    <div style="margin-bottom: 5px">
      <span>时长</span>
      <el-input-number
        :precision="2"
        :step="0.1"
        v-model="editor.currentSprite.duration"
        label="left"
        style="display: inline-flex"
        @change="cha2"
      ></el-input-number>
      <el-button class="el-button--primary" @click="cha2">查2</el-button>
      <el-button class="el-button--primary" @click="cha3">原</el-button>

      <el-button
        v-if="editor.currentSprite.type === 'flash'"
        class="el-button--primary"
        @click="computFlashTime()"
        >计算flash时长</el-button
      >
    </div>

    <div style="margin-bottom: 5px">
      <span>透明：</span>
      <el-switch
        v-model="editor.currentSprite.isTransparent"
        active-color="#13ce66"
        inactive-color="#ff4949"
      ></el-switch>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "commonProp",
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
  },
  methods: {
    changeText(event) {
      let value = event;
      console.log(1111111);
      if (value.length < 10) {
        this.editor.currentSprite.name = value;
      } else {
        this.editor.currentSprite.name = value.substring(0, 10);
      }
    },
    computFlashTime() {
      let flash = document.getElementById("flash_" + this.editor.currentSprite.id);
      let totalFrames = flash.TotalFrames();
      this.editor.currentSprite.duration = totalFrames / 30;

      console.log();
    },
    fuhe1() {
      this.editor.currentSprite.left = 80;
      this.editor.currentSprite.top = 130;
      this.editor.currentSprite.width = 700;
      this.editor.currentSprite.height = 400;
    },
    fuheGreen1() {
      this.editor.currentSprite.left = 1280 - 640;
      this.editor.currentSprite.top = 0;
      this.editor.currentSprite.width = 640;
      this.editor.currentSprite.height = 720;
    },
    fuheGreen2() {
      this.editor.currentSprite.left = 1280 - 480;
      this.editor.currentSprite.top = 0;
      this.editor.currentSprite.width = 480;
      this.editor.currentSprite.height = 720;
    },
    fuheGreen3() {
      this.editor.currentSprite.left = 1280 - 640;
      this.editor.currentSprite.top = 0;
      this.editor.currentSprite.width = 640;
      this.editor.currentSprite.height = 720;
    },
    fuheBg() {
      this.editor.currentSprite.left = 0;
      this.editor.currentSprite.top = 0;
      this.editor.currentSprite.width = 1280;
      this.editor.currentSprite.height = 720;
    },
    cha1() {
      var v1 = document.getElementById("vd" + this.editor.currentSprite.id);
      v1.currentTime = this.editor.currentSprite.clip + this.editor.currentSprite.start;
    },
    cha2() {
      var v1 = document.getElementById("vd" + this.editor.currentSprite.id);
      v1.currentTime =
        this.editor.currentSprite.clip +
        this.editor.currentSprite.start +
        this.editor.currentSprite.duration;
    },
    cha3() {
      var v1 = document.getElementById("vd" + this.editor.currentSprite.id);
      var duration = v1.duration;
      this.editor.currentSprite.duration = duration;
    },
  },
};
</script>

<style scoped>
.xxxActive {
  border: 1px solid #f00;
}
</style>

<template>
  <div class="biaoshi_kankan" :style="style1">
    <div :id="'lottie' + item.id" ref="locont"></div>
  </div>
</template>

<script>
import * as lottie from "lottie-web";

export default {
  name: "lottieShow",
  component: {},
  props: {
    item: Object,
    style1: String,
  },
  data() {
    return {};
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      var dom = document.getElementById("lottie" + this.item.id); //dom = this.refs.locont;
      console.log(dom);
      var that = this;

      setTimeout(() => {
        lottie.loadAnimation({
          path: that.item.preview, // the path to the animation json
          loop: true,
          autoplay: true,
          name: "kakakaka",
          renderer: "svg",
          container: dom, //dom, //// the dom element that will contain the animation
        });
      }, 0);
    },
    // watch: {
    //   item: {
    //     handler(val, oldVal) {
    //       console.log(`new: ${val}, old: ${oldVal}`);
    //       this.loadData();
    //     },
    //     deep: true,
    //   },
    // },
  },
};
</script>

<style></style>

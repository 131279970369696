<template>
  <div>
    <router-link style="margin-right: 200px; color: aliceblue" :to="{ path: '/user/workplace' }"
      >微课视频动画智创平台</router-link
    >

    <el-button
      type="warning"
      icon="el-icon-undo"
      style="margin-right: 20px; color: aliceblue"
      @click="undo"
      >撤销</el-button
    >

    <a target="_blank" :href="'/player/' + $route.params.id" style="margin-right: 15px">
      <el-button type="success" icon="el-icon-preview">预览</el-button>
    </a>
    <el-button type="primary" icon="el-icon-search" @click="voicer">语音队列</el-button>

    <span style="margin-right: 20px; color: aliceblue">标题</span>
    <el-input
      style="width: 200px; margin-right: 20px"
      v-model="editor.project.name"
      placeholder="请输入标题"
    ></el-input>
    <!-- <el-input
      style="width: 150px; margin-right: 20px"
      v-model="editor.project.course"
      placeholder="请输入课程名称"
    ></el-input> -->
    <!-- <el-input
      style="width: 100px; margin-right: 20px"
      v-model="editor.project.teacher"
      placeholder="请输入教师姓名"
    ></el-input> -->

    <el-button type="warning" icon="el-icon-search" @click="addSence">插入场景</el-button>
    <el-button type="success" icon="el-icon-search" @click="addText">插入文字</el-button>
    <!--    <el-button type="primary" icon="el-icon-search">插入视频</el-button>-->
    <!--    <el-button type="primary" icon="el-icon-search">插入音频</el-button>-->
    <!--    <el-button type="danger" icon="el-icon-search">插入flash</el-button>-->

    <el-upload
      style="display: inline-flex; margin-left: 10px; margin-right: 10px"
      action="/api/upload/create"
      :show-file-list="false"
      :data="{ projectId: $route.params.id }"
      :on-success="addSprite1"
      multiple
    >
      <el-button size="small" type="primary">插入图片、音频、视频、flash</el-button>
    </el-upload>

    <el-upload
      style="display: inline-flex; margin-left: 10px; margin-right: 10px"
      action="/api/upload/CreatePPT"
      :show-file-list="false"
      :data="{ projectId: $route.params.id }"
      :on-success="addPpt1"
      multiple
    >
      <el-button size="small" type="primary">插入PPT</el-button>
    </el-upload>

    <el-button type="danger" icon="el-icon-search" @click="save">保存</el-button>
    <el-button type="primary" icon="el-icon-search" @click="render">发布</el-button>

    <!--    <a target="_blank" :href="$route"><el-button type="danger" @click="save" icon="el-icon-search">发布</el-button></a>-->

    <!-- <el-button type="primary" icon="el-icon-search" @click="copySprite()">复制</el-button>
    <el-button type="primary" icon="el-icon-search" @click="pasteSprite()">粘贴</el-button> -->
    <!-- <el-button type="danger" icon="el-icon-search" @click="delSprite()">删除</el-button> -->
    <el-button type="primary" icon="el-icon-search" @click="pasteSence()">粘贴场景</el-button> -->
  </div>
</template>

<script>
import ObjectID from "bson-objectid";
import { mapState } from "vuex";
import axios from "axios";
import key from "keymaster";
import { ElMessage } from "element-plus";
import _ from "lodash";

export default {
  name: "header",
  data() {
    return {
      fileList: [],
      addTextConfig: {
        left: 50,
        top: 50,
      },
    };
  },
  mounted() {
    let that = this;
    key("ctrl+s", function () {
      that.save();
      return false;
    });
    key("ctrl+c", function () {
      that.copySprite();
      return false;
    });
    key("ctrl+v", function () {
      that.pasteSprite();
      return false;
    });
    key("delete", function () {
      that.delSprite();
      return false;
    });
    key("ctrl+d", function () {
      that.copySpriteText();
      return false;
    });
    key("ctrl+b", function () {
      that.editor.currentSprite.textEffect = "fadeOut";
      return false;
    });
    key("alt+t", function () {
      //that.editor.deleteItem.push(that.editor.currentSprite);
      let _type = that.editor.currentSprite.type;

      if (_type == "image") {
        that.editor.currentSprite.type = "text";
        that.editor.currentSprite.text = that.editor.currentSprite.note;
        that.editor.currentSprite.fontSize = 18;
      } else {
        that.editor.currentSprite.type = "image";
      }

      return false;
    });
    // key('ctrl+c', this.copySprite());
    // key('ctrl+v', this.pasteSprite());
    // key('delete', this.delSprite());
  },
  beforeUnmount() {
    console.log("beforeUnmount");
    key.unbind("ctrl+s");
    key.unbind("ctrl+c");
    key.unbind("ctrl+v");
    key.unbind("delete");
    key.unbind("ctrl+d");

    key.unbind("ctrl+b");
    key.unbind("alt+t");
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
  },
  methods: {
    addSence() {
      let id = ObjectID().toHexString();
      let sence = { id: id, name: "sence1", sprites: [] };
      this.editor.meta.sences.push(sence);
      this.editor.currentSenceId = id;
      this.editor.currentSence = this.editor.meta.sences[this.editor.meta.sences.length - 1];
    },
    save() {
      //let _id = this.$route.params.id;
      //console.log(_id);
      let that = this;

      this.editor.project.meta = JSON.stringify(that.editor.meta);

      axios
        .post("/api/project/edit", JSON.stringify(that.editor.project), {
          headers: { "Content-Type": "application/json" },
        })
        .then(function (response) {
          //console.log(response);
          console.log("save ok:" + response.result);

          ElMessage({ message: "保存成功！", duration: 500 });
        });
    },
    copySprite() {
      let str = JSON.stringify(this.editor.currentSprite);
      console.log(str);
      //this.editor.copySprite = str;
      localStorage.setItem("copySprite", str);
    },
    pasteSprite() {
      //this.editor.copySprite
      let obj = JSON.parse(localStorage.getItem("copySprite"));
      obj.id = ObjectID().toHexString();
      // obj.x = obj.x + 20;
      // obj.y = obj.y + 20;
      // obj.left = this.addTextConfig.left;
      // obj.top = this.addTextConfig.top;
      console.log(obj);

      this.editor.copySprite = JSON.stringify(obj);

      this.editor.currentSence.sprites.push(obj);
      this.currentSprite = obj;
      //this.movePastePosition();
    },
    pasteSence() {
      let newSence = JSON.parse(localStorage.getItem("copySence"));
      newSence.id = ObjectID().toHexString();
      newSence.sprites.forEach((x) => {
        x.id = ObjectID().toHexString();
      });

      this.editor.meta.sences.unshift(newSence);
      this.editor.currentSence = newSence;
    },
    delSprite() {
      var delItem = this.editor.currentSence.sprites.filter(
        (t) => t.id == this.editor.currentSprite.id
      )[0];
      console.log(delItem);

      this.editor.deleteItem.push(delItem);

      this.editor.currentSence.sprites = this.editor.currentSence.sprites.filter(
        (t) => t.id != this.editor.currentSprite.id
      );
      this.editor.currentSprite = null;
    },
    undo() {
      if (!_.isEmpty(this.editor.deleteItem)) {
        let sprite = this.editor.deleteItem.pop();
        console.log(sprite);
        this.editor.currentSence.sprites.unshift(sprite);
        this.editor.currentSprite = sprite;
      }
    },
    copySpriteText() {
      let a = this.editor.currentSprite.note;
      console.log(a);
      this.editor.currentSence.note += a;
    },
    addSprite1(response) {
      var obj = JSON.parse(response.result.meta);
      obj.id = ObjectID().toHexString();
      obj.left = this.addTextConfig.left;
      obj.top = this.addTextConfig.top;
      console.log(obj);

      obj.clip = 0;
      // obj.start = 0;
      // obj.duration = 0;
      obj.muted = false;
      // obj.textEffect = "none";

      if (obj.type == "audio") {
        (obj.width = 300), (obj.height = 100);
      }

      //console.log(this.editor.currentSence.sprites);
      this.editor.currentSence.sprites.push(obj);
      //this.movePastePosition();
    },
    addPpt1(response) {
      // let scenes = [
      //   {
      //     id: "61b6dbafa8abf900017ae2a7",
      //     name: "sence1",
      //     sprites: [
      //       {
      //         name: "建筑室内设计_专业教学资源库_片头_1080p.mp4",
      //         type: "video",
      //         path: "/resource/src/61b6dc7c0052fd15031f80c1.mp4",
      //         preview: "/resource/preview/61b6dc7c0052fd15031f80c1.mp4",
      //         width: 1280,
      //         height: 720,
      //         size: 8600239,
      //         duration: 7.04,
      //         id: "61b6dc82a8abf900017ae2a8",
      //         left: 0,
      //         top: 0,
      //         clip: 0,
      //         muted: false,
      //         active: false,
      //         start: 0,
      //         textEffect: "none",
      //       },
      //     ],
      //   },
      //   {
      //     id: "61b6dcc9a8abf900017ae2ab",
      //     name: "sence1",
      //     sprites: [
      //       {
      //         name: "08绿.mp4",
      //         type: "video",
      //         path: "/resource/src/61b71a340052fd150321eebb.mp4",
      //         preview: "/resource/preview/61b71a340052fd150321eebb.mp4",
      //         width: 830,
      //         height: 730,
      //         size: 37293738,
      //         duration: 19.92,
      //         id: "61b71a396a25d0000103deff",
      //         left: 640,
      //         top: -9,
      //         clip: 0,
      //         muted: false,
      //         active: false,
      //         start: 0,
      //         textEffect: "none",
      //         green: true,
      //       },
      //       {
      //         name: "幻灯片12.PNG",
      //         type: "image",
      //         path: "/resource/src/61b723010052fd150321fb3c.png",
      //         preview: "/resource/preview/61b723010052fd150321fb3c.png",
      //         width: 700,
      //         height: 400,
      //         size: 822890,
      //         duration: 0.8,
      //         id: "61b723034031790001d3a9ca",
      //         left: 80,
      //         top: 130,
      //         clip: 0,
      //         muted: false,
      //         active: false,
      //         start: null,
      //         textEffect: "none",
      //       },
      //     ],
      //   },
      // ];
      var objs = response.result.res;
      objs.forEach((x) => this.editor.meta.sences.push(x));
    },
    addText() {
      let obj = {
        id: ObjectID().toHexString(),
        left: this.addTextConfig.left,
        top: this.addTextConfig.top,
        width: 200,
        height: 100,
        color: "#000",
        fontSize: 24,
        type: "text",
        name: "新建文字1",
        text: "新建文字1",
        start: 0,
        duration: 5,
        textEffect: "none",
      };

      this.editor.currentSence.sprites.push(obj);
      //this.movePastePosition();
    },
    movePastePosition() {
      if (this.addTextConfig.left < 300) {
        this.addTextConfig.left += 20;
        this.addTextConfig.top += 20;
      } else {
        this.addTextConfig.left = 50;
        this.addTextConfig.top = 50;
      }
    },
    render() {
      let _id = this.$route.params.id;
      //console.log(_id);

      axios.post("/api/Render/Start/" + _id).then(function (response) {
        console.log(response.data);

        ElMessage({ message: "加入渲染队列成功！", duration: 500 });
      });
    },
    voicer() {
      let _id = this.$route.params.id;
      //console.log(_id);

      axios.get("/api/project/voicer/" + _id).then(function (response) {
        console.log(response.data);

        ElMessage({ message: "语音生成加入队列！", duration: 500 });
      });
    },
  },
};
</script>

<style scoped></style>

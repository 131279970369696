<script>
import { mapState } from "vuex";
import axios from "axios";
// import xpath from "xpath";
// import xmldom from "xmldom";
// import _ from "lodash";
// import { ElMessage } from "element-plus";

import lottieShow from "../../components/editor/lottieShow";

export default {
  name: "test2",
  components: {
    lottieShow,
  },
  props: {},
  data() {
    return {
      list: [],
      pefix: "https://resources.laihua.com/",
      citem: {
        id: "5048a5c4-d86d-4dc8-bdfd-a6c5d6a28215",
        url: "https://resources.laihua.com/2022-6-14/fc344d96-44dc-4110-ba4f-872e7841ace2.json",
      },
    };
  },
  mounted() {
    //
    //this.imgList = [{ url: "1111" }, { url: "1111" }];
    this.lodaData();
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
  },
  methods: {
    lodaData() {
      // let _id = this.$route.params.id;
      let that = this;

      let url =
        "https://www.laihua.com/api/common/material/findCharacterActionByName?fPage=24&sOfPage=24&pIndex=1&name=%E8%AE%B2%E8%A7%A3&category=100485&style=78";

      axios.get(url).then(function (response) {
        console.log(response.data.data);

        that.list = response.data.data;
      });
    },
    select(item) {
      //console.log(item.url);
      var obj = { id: item.id, url: this.pefix + item.url };
      console.log(obj);
      this.citem = obj;
      //
    },
  },
};
</script>

<template>
  <div class="js_cont" v-for="item of list" :key="item.id">
    <img :src="pefix + item.thumbnailUrl" class="img1" @click="select(item)" />
  </div>

  <div class="js_cont" v-for="item of list" :key="item.id">
    <lottieShow class="img1" :item="{ id: item.id, url: this.pefix + item.url }"></lottieShow>
  </div>
</template>

<style>
.img1 {
  width: 88px;
  height: 160px;
}
.js_cont {
  display: inline-block;
}
</style>

<template>
  <div
    :id="'ap' + sprite.id"
    :key="sprite.id + '1'"
    class="spriteMain"
    :style="computStyle()"
    v-html="text2html2()"
  ></div>
</template>

<script>
import { mapState } from "vuex";

// eslint-disable-next-line no-unused-vars
import ainme from "animejs";

import gsap from "../../gsap/esm/all.js";
import TextPlugin from "../../gsap/esm/TextPlugin.js";
gsap.registerPlugin(TextPlugin);

export default {
  name: "playText",
  components: {},
  props: {
    sceneIndex: Number,
    spriteIndex: Number,
    sprite: Object,
  },
  data() {
    return {
      visible: false,
      rafId: 0,
      startTime: 0,
      endTime: 0,
      startStat: false,
      endStat: false,
      animateClass1: "  ",
      animateClass2: "  ",
      animateClass3: "  ",
      animateClass4: "  ",
      html: "",
    };
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    let that = this;
    console.log(
      "sprite mounted playVideo--- index:" +
        this.spriteIndex +
        "---duration:" +
        this.sprite.duration
    );
    //console.log(this.$refs.sprite1);

    //动画初始化
    // this.ainmeObj = ainme({
    //   targets: "#ap" + this.sprite.id,
    //   keyframes: [
    //     { translateY: -100, opacity: 0 },
    //     { translateY: 0, opacity: 1 },
    //   ],
    //   duration: 300,
    //   easing: "cubicBezier(0.250, 0.460, 0.450, 0.940)",
    //   delay: 0,
    //   autoplay: false,
    // });

    //this.loadeddata();
    //
    let dom1 = document.querySelector("#ap" + this.sprite.id);

    if (this.sprite.textEffect === "typeStr") {
      //typeStr
      this.gsap1 = gsap.to(dom1, {
        duration: this.sprite.duration - 1,
        //text: this.sprite.text,
        text: this.text2html1(),
        ease: "none",
        paused: true,
      });
    } else {
      //fageOut
      this.gsap1 = gsap.fromTo(
        "#ap" + this.sprite.id,
        { opacity: 0 },
        { opacity: 1, duration: this.sprite.duration }
      );
      this.gsap1.pause();
    }
  },
  watch: {
    "sprite.runStatus": {
      handler: function (newVal, oldVal) {
        console.log(
          "watch sprite.runStatus---spriteIndex:" +
            this.spriteIndex +
            "---newVal:" +
            newVal +
            "---oldVal:" +
            oldVal
        );

        if (newVal == 2) {
          this.visible = true;

          //开始本精灵
          this.begin();
        }

        if (newVal == 4) {
          //this.visible = false;

          //结束本精灵
          this.stop();
        }

        if (newVal == 9) {
          this.visible = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    begin() {
      this.beginTime = window.performance.now();
      this.startTime = this.beginTime + this.sprite.start * 1000;
      this.endTime = this.startTime + this.sprite.duration * 1000;
      //raf循环
      this.rafRun1();
    },
    start() {
      //chrome提示错误是因为chrome不允许自动播放视频，需要先点击下页面触发
      //this.$refs.sprite1.play();

      // this.animateClass1 = "animate__animated animate__fadeInDown";
      // this.animateClass2 = "animate__animated animate__fadeInDown";
      // this.animateClass3 = "animate__animated animate__flipInX";
      // this.animateClass4 = "animate__animated animate__fadeInUp";

      //console.log("ainmeObj.play()");

      this.gsap1.play();
    },
    stop() {
      //退出循环
      this.endStat = true;

      //退出动效
      // this.$refs.scene1.classList.add("animate__animated");
      // this.$refs.scene1.classList.add("animate__fadeOut");
    },
    rafRun1() {
      //判断开始
      if (window.performance.now() > this.startTime) {
        if (!this.startStat) {
          this.start();
          this.startStat = true;
        }
        this.rafId = requestAnimationFrame(this.rafRun2);
      } else {
        this.rafId = requestAnimationFrame(this.rafRun1);
      }
    },
    rafRun2() {
      //判断结束
      if (window.performance.now() > this.endTime) {
        cancelAnimationFrame(this.rafId);
        this.editor.meta.scenes[this.sceneIndex].sprites[this.spriteIndex].runStatus = 4;
      } else {
        this.rafId = requestAnimationFrame(this.rafRun2);
      }
    },
    computStyle() {
      let style = "";
      style += "z-index: " + (100 - this.spriteIndex) + ";";
      style += "position: absolute;";
      style += "left: " + this.sprite.left + "px;";
      style += "top: " + this.sprite.top + "px;";
      style += "width: " + this.sprite.width + "px;";
      style += "height: " + this.sprite.height + "px;";
      style += "color: " + this.sprite.color + ";";
      style += "font-family: '微软雅黑';";
      style += "font-size: " + this.sprite.fontSize + "px;";
      // if (!this.startStat) {
      //   style += "visibility: hidden;";
      // } else {
      //   style += "visibility: visible;";
      // }

      //style += "opacity: " + "0;";

      if (this.sprite.textEffect === "fadeOut") {
        style += "opacity: 0;";
      }

      // if(this.endStat){
      //   style += "visibility: hidden;";
      // }

      if (this.sceneIndex == 1) {
        style += "text-shadow: 4px 4px 2px rgba(150, 150, 150, 1);";
      }

      //console.log(style);
      return style;
    },
    loadeddata() {
      this.$refs.sprite1.currentTime = this.sprite.clip;
    },
    text2html1() {
      let htmlString = this.sprite.text.replaceAll("\n", "<br/>\n");
      return htmlString;
    },
    text2html2() {
      if (this.sprite.textEffect === "typeStr") {
        return "";
      } else {
        return this.sprite.text;
      }
    },
    title1() {
      return this.sprite.text;
    },
    list1() {
      let arr = this.sprite.text.split(/[\n]/);
      var filteredArr = arr.filter(function (el) {
        return el != "";
      });
      return filteredArr;
    },
    list2() {
      let arr = this.sprite.text.split(/[\n]/);
      var filteredArr = arr.filter(function (el) {
        return el != "";
      });
      return filteredArr;
    },
    content1() {
      let arr = this.sprite.text.split(/[\n]/);
      var filteredArr = arr.filter(function (el) {
        return el != "";
      });
      return filteredArr;
    },
  },
};
</script>

<style>
.test1 {
  position: absolute;
  visibility: visible;
}
.spriteHide {
  visibility: hidden;
}

.spriteShow {
  visibility: visible;
}
</style>

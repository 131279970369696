<template>
  <!-- no green -->
  <audio ref="audio1"
         :src="item.preview"
         :width="item.width"
         :height="item.height"
         preload
         controls
         :muted="item.muted"
         @loadeddata = "loadeddata(item)"
         @click="controlPlay(item)"></audio>
</template>

<script>
export default {
  name: "audioShow",
  props:{
    item: Object
  },
  data(){
    return {

    }
  },
  mounted() {
  },
  methods:{
    loadeddata(item) {
      let audio1 = this.$refs.audio1;
      if(item.clip === 0) {
        audio1.currentTime = 0.1;
      }
      else{
        audio1.currentTime = item.clip;
      }
    },
    controlPlay(item) {
      let audio1 = this.$refs.audio1;
      if (audio1.paused){
        audio1.currentTime = item.clip;
        audio1.play();
      }
      else{
        audio1.pause();
      }
    }
  },
};
</script>

<style scoped>

</style>
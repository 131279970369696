<template>
  <div>
    <div>全局转场效果</div>

    <el-button
      class="el-button"
      :class="editor.meta.exitEffect == 'none' ? 'el-button--primary' : ''"
      @click="editor.meta.exitEffect = 'none'"
      >none</el-button
    >

    <el-button
      class="el-button"
      :class="editor.meta.exitEffect == 'fadeOut' ? 'el-button--primary' : ''"
      @click="editor.meta.exitEffect = 'fadeOut'"
      >fadeOut</el-button
    >

    <el-button
      class="el-button"
      :class="editor.meta.exitEffect == 'fadeOutLeft' ? 'el-button--primary' : ''"
      @click="editor.meta.exitEffect = 'fadeOutLeft'"
      >fadeOutLeft</el-button
    >

    <div><span style="line-height: 40px">全局背景：</span></div>

    <div
      v-for="(item, index) of editor.bgList"
      :key="index"
      style="display: inline-flex; padding: 5px"
    >
      <img
        v-if="item.type === 'image'"
        style="width: 120px; height: 75px"
        :class="editor.meta.backgroundImage == item.url ? 'actived' : ''"
        @click="editor.meta.backgroundImage = item.url"
        :src="item.url"
      />
      <video
        v-if="item.type === 'video'"
        style="width: 120px; height: 75px"
        :class="editor.meta.backgroundImage == item.url ? 'actived' : ''"
        @click="editor.meta.backgroundImage = item.url"
        :src="item.url"
      ></video>
    </div>

    <div style="margin-bottom: 5px; padding-right: 15px">
      <span>字幕提取：</span>
      <el-input type="textarea" :rows="30" placeholder="" v-model="editor.currentSence.note">
        @change="changeText"
      </el-input>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "sceneConfig",
  data() {
    return {
      bgList: [],
    };
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
  },
  methods: {},
};
</script>

<style scoped>
.actived {
  border: 1px solid #f00;
}
</style>

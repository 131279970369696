<template>
  <div>
    <div>工具</div>

    <el-button class="el-button el-button--primary" @click="clearEmptyImage"
      >清除空余图片</el-button
    >

    <el-button class="el-button el-button--primary" @click="genNote2Voice"
      >将每页中的字幕生成配音</el-button
    >

    <div style="margin-top: 15px; padding-right: 15px">
      <span>标题：</span>
      <el-input
        style="width: 100%"
        v-model="editor.project.name"
        placeholder="请输入标题"
      ></el-input>
    </div>

    <div style="margin-top: 15px; padding-right: 15px">
      <span>课程：</span>
      <el-input style="width: 100%" v-model="editor.project.course" placeholder=""></el-input>
    </div>

    <div style="margin-top: 15px; padding-right: 15px">
      <span>所属教师：</span>
      <el-input style="width: 100%" v-model="editor.project.teacher" placeholder=""></el-input>
    </div>

    <div style="margin-top: 15px; padding-right: 15px">
      <span>自动分词：</span>
      <el-input style="width: 100%" v-model="editor.currentSence.fenci" placeholder=""></el-input>
    </div>

    <div style="margin-top: 15px; padding-right: 15px">
      <span>关键字：</span>
      <el-input style="width: 100%" v-model="editor.currentSence.keyword" placeholder=""></el-input>
    </div>

    <el-button style="margin-top: 15px" class="el-button el-button--primary" @click="genVoice"
      >将字幕生成配音</el-button
    >

    <div style="margin-top: 5px; padding-right: 15px">
      <span>字幕：</span>
      <el-input type="textarea" :rows="10" placeholder="" v-model="editor.currentSence.note">
        @change="changeText"
      </el-input>
    </div>

    <genPptx></genPptx>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import axios from "axios";
import ObjectID from "bson-objectid";
import { ElMessage } from "element-plus";
import genPptx from "./genPptx";

export default {
  name: "sceneConfig",
  components: { genPptx },
  data() {
    return {
      bgList: [],
    };
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
  },
  methods: {
    clearEmptyImage() {
      _.forEach(this.editor.meta.sences, function (value) {
        //console.log(value);
        value.sprites = _.filter(value.sprites, function (sprite) {
          return !(sprite.type == "image" && _.isNil(sprite.path));
        });
      });
    },

    async createVoice(text) {
      var voice1 = {
        name: "voice1",
        type: "audio",
        path: "",
        preview: "",
        width: 300,
        height: 100,
        size: 1111,
        duration: 1.0,
        id: ObjectID().toHexString(),
        left: 0,
        top: 50,
        clip: 0,
        muted: false,
        active: false,
      };

      let { data } = await axios.post("/api/tts/GenVoice?text=" + text);
      console.log(data.result.text);
      voice1.path = data.result.webPath;
      voice1.preview = data.result.webPath;
      voice1.duration = data.result.duration;

      return voice1;
    },
    async genFenci(text) {
      let { data } = await axios.post("/api/tts/GenFenci?text=" + text);
      return data.result.fenci;
    },
    async genKeyword(text) {
      let { data } = await axios.post("/api/tts/GenKeyword?text=" + text);
      return data.result.keyword;
    },

    async genNote2Voice() {
      let that = this;

      //顺序迭代
      for await (const sence of this.editor.meta.sences) {
        if (sence.note) {
          sence.fenci = await that.genFenci(sence.note);
          sence.keyword = await that.genKeyword(sence.note);

          let newSprite = await that.createVoice(sence.note);
          console.log(newSprite);

          let sprites = _.filter(sence.sprites, function (sprite) {
            return sprite.type != "audio";
          });
          sprites.push(newSprite);
          sence.sprites = sprites;

          ElMessage({ message: sence.note + " 已生成", type: "success" });
        }
      }
    },

    async genVoice() {
      let that = this;
      let sence = this.editor.currentSence;
      //顺序迭代
      if (sence.note) {
        sence.fenci = await that.genFenci(sence.note);
        sence.keyword = await that.genKeyword(sence.note);

        let newSprite = await that.createVoice(sence.note);
        console.log(newSprite);

        let sprites = _.filter(sence.sprites, function (sprite) {
          return sprite.type != "audio";
        });
        sprites.push(newSprite);
        sence.sprites = sprites;

        ElMessage({ message: sence.note + " 已生成", type: "success" });
      }
    },
  },
};
</script>

<style scoped>
.actived {
  border: 1px solid #f00;
}
</style>

<script setup>
import axios from "axios";
import xpath from "xpath";
import xmldom from "xmldom";
import _ from "lodash";
import { reactive } from "vue";

let data = reactive([]);

axios
  .get("http://cn.bing.com/images/async", {
    params: {
      q: "创业",
    },
  })
  .then(function (response) {
    //console.log(response.data);

    let dom = xmldom.DOMParser;
    let xml = response.data;
    let doc = new dom().parseFromString(xml);
    let nodes = xpath.select("//div[@class='iuscp isv']", doc);

    //let title = xpath.select("/div[1]/div[1]/a", nodes[0]);
    //console.log(nodes[0].localName + ": " + nodes[0].firstChild.data);

    //let n1 = xpath.select("//a[@m]", doc);  childNodes
    //let n2 = xpath.select("//div[@class='img_info hon']/span[@class='nowrap']", doc);

    console.log(nodes[0]);
    console.log(nodes[0].firstChild.firstChild.attributes[2].nodeValue);
    console.log(nodes[0].firstChild.childNodes[1].firstChild.firstChild.nodeValue);

    _.forEach(nodes, function (o) {
      let m = JSON.parse(o.firstChild.firstChild.attributes[2].nodeValue);
      let newObj = { url: m.turl };
      data.push(newObj);
    });

    //console.log(n2);
  })
  .catch(function (error) {
    console.log(error);
  })
  .then(function () {
    // 总是会执行
  });

axios
  .get("http://cn.bing.com/images/async", {
    params: {
      q: "大学生",
    },
  })
  .then(function (response) {
    //console.log(response.data);

    let dom = xmldom.DOMParser;
    let xml = response.data;
    let doc = new dom().parseFromString(xml);
    let nodes = xpath.select("//div[@class='iuscp isv']", doc);

    //let title = xpath.select("/div[1]/div[1]/a", nodes[0]);
    //console.log(nodes[0].localName + ": " + nodes[0].firstChild.data);

    //let n1 = xpath.select("//a[@m]", doc);  childNodes
    //let n2 = xpath.select("//div[@class='img_info hon']/span[@class='nowrap']", doc);

    console.log(nodes[0]);
    console.log(nodes[0].firstChild.firstChild.attributes[2].nodeValue);
    console.log(nodes[0].firstChild.childNodes[1].firstChild.firstChild.nodeValue);

    _.forEach(nodes, function (o) {
      let m = JSON.parse(o.firstChild.firstChild.attributes[2].nodeValue);
      let newObj = { url: m.turl };
      data.push(newObj);
    });

    //console.log(n2);
  })
  .catch(function (error) {
    console.log(error);
  })
  .then(function () {
    // 总是会执行
  });

axios
  .get("http://cn.bing.com/images/async", {
    params: {
      q: "信心",
    },
  })
  .then(function (response) {
    //console.log(response.data);

    let dom = xmldom.DOMParser;
    let xml = response.data;
    let doc = new dom().parseFromString(xml);
    let nodes = xpath.select("//div[@class='iuscp isv']", doc);

    //let title = xpath.select("/div[1]/div[1]/a", nodes[0]);
    //console.log(nodes[0].localName + ": " + nodes[0].firstChild.data);

    //let n1 = xpath.select("//a[@m]", doc);  childNodes
    //let n2 = xpath.select("//div[@class='img_info hon']/span[@class='nowrap']", doc);

    console.log(nodes[0]);
    console.log(nodes[0].firstChild.firstChild.attributes[2].nodeValue);
    console.log(nodes[0].firstChild.childNodes[1].firstChild.firstChild.nodeValue);

    _.forEach(nodes, function (o) {
      let m = JSON.parse(o.firstChild.firstChild.attributes[2].nodeValue);
      let newObj = { url: m.turl };
      data.push(newObj);
    });

    //console.log(n2);
  })
  .catch(function (error) {
    console.log(error);
  })
  .then(function () {
    // 总是会执行
  });
</script>

<template>
  <img class="img" v-for="(item, index) of data" :key="index" :src="item.url" />
</template>

<style>
.img {
  width: 200px;
  height: 150px;
}
</style>

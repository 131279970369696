<template>
  <video
    :key="sprite.id"
    class="spriteMain"
    :class="visible ? 'spriteShow' : 'spriteHide'"
    :style="computStyle()"
    ref="sprite1"
    :src="sprite.preview + '#t=0.01'"
    :muted="sprite.muted"
    :width="sprite.width"
    :height="sprite.height"
    preload="auto"
    @loadeddata="loadeddata"
  ></video>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "playVideo",
  components: {},
  props: {
    sceneIndex: Number,
    spriteIndex: Number,
    sprite: Object,
  },
  data() {
    return {
      visible: false,
      rafId: 0,
      startTime: 0,
      endTime: 0,
      startStat: false,
    };
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    let that = this;
    console.log("sprite mounted playVideo--- index:" + this.spriteIndex + "---duration:" + this.sprite.duration);
    //console.log(this.$refs.sprite1);
  },
  watch: {
    "sprite.runStatus": {
      handler: function (newVal, oldVal) {
        console.log(
          "watch sprite.runStatus---spriteIndex:" + this.spriteIndex + "---newVal:" + newVal + "---oldVal:" + oldVal
        );

        if (newVal == 2) {
          this.visible = true;

          //开始本精灵
          this.begin();
        }

        if (newVal == 4) {
          //this.visible = false;

          //结束本精灵
          this.stop();
        }

        if (newVal == 9) {
          this.visible = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    begin() {
      this.beginTime = window.performance.now();
      this.startTime = this.beginTime + this.sprite.start * 1000;
      this.endTime = this.startTime + this.sprite.duration * 1000;
      //raf循环
      this.rafRun1();
    },
    start() {
      //chrome提示错误是因为chrome不允许自动播放视频，需要先点击下页面触发
      this.$refs.sprite1.play();
    },
    stop() {
      //退出循环
      this.startStat = true;

      //退出动效
      // this.$refs.scene1.classList.add("animate__animated");
      // this.$refs.scene1.classList.add("animate__fadeOut");
    },
    rafRun1() {
      //判断开始
      if (window.performance.now() > this.startTime) {
        if (!this.startStat) {
          this.start();
          this.startStat = true;
        }
        this.rafId = requestAnimationFrame(this.rafRun2);
      } else {
        this.rafId = requestAnimationFrame(this.rafRun1);
      }
    },
    rafRun2() {
      //判断结束
      if (window.performance.now() > this.endTime) {
        cancelAnimationFrame(this.rafId);
        this.$refs.sprite1.pause();
        this.editor.meta.scenes[this.sceneIndex].sprites[this.spriteIndex].runStatus = 4;
      } else {
        this.rafId = requestAnimationFrame(this.rafRun2);
      }
    },
    computStyle() {
      let style = "";
      style += "z-index: " + (100 - this.spriteIndex) + ";";
      style += "position: absolute;";
      style += "left: " + this.sprite.left + "px;";
      style += "top: " + this.sprite.top + "px;";
      style += "width: " + this.sprite.width + "px;";
      style += "height: " + this.sprite.height + "px;";

      //console.log(style);
      return style;
    },
    loadeddata() {
      this.$refs.sprite1.currentTime = this.sprite.clip;
    },
  },
};
</script>

<style scoped>
.test1 {
  position: absolute;
}
.spriteHide {
  visibility: hidden;
}

.spriteShow {
  visibility: visible;
}
</style>

<template>
  <!-- has green -->
  <video
    :id="'vd' + item.id"
    ref="video1"
    style="display: none"
    :src="item.preview"
    :width="item.width"
    :height="item.height"
    :muted="item.muted"
    @loadeddata="loadeddata(item)"
    @timeupdate="timeupdate(item)"
  ></video>
  <canvas
    ref="canvas1"
    :width="item.width"
    :height="item.height"
    @click="controlPlay(item)"
  ></canvas>
</template>

<script>
import tinycolor from "tinycolor2";
import { mapState } from "vuex";

export default {
  name: "videoGreen",
  props: {
    item: Object,
  },
  data() {
    return {
      data1: this.item,
    };
  },
  mounted() {
    this.initSeriously();
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
    greencolor() {
      if (!this.editor.currentSprite.gColor) {
        return [165 / 255, 215 / 255, 146 / 255, 1];
      } else {
        let color = tinycolor(this.editor.currentSprite.gColor);
        let rgb = color.toRgb();
        return [rgb.a / 255, rgb.g / 255, rgb.b / 255, 1];
      }
    },
  },
  methods: {
    initSeriously() {
      console.log(this.$refs.video1);
      //初始化seriously
      // eslint-disable-next-line no-undef
      this.seriously = new Seriously();
      //定义源
      this.source = this.seriously.source(this.$refs.video1); //"#video1"
      //抠像插件
      this.chroma = this.seriously.effect("chroma");
      //亮度插件
      //let bright = this.seriously.effect("brightness-contrast");
      //重新格式化
      this.reformat = this.seriously.transform("reformat");
      //最终输出
      this.target = this.seriously.target(this.$refs.canvas1);

      //抠像配置参数：用户需要传入参数6个,以下均为seriously默认值
      this.chroma.screen = this.greencolor;
      this.chroma.weight = 1;
      this.chroma.balance = 1;
      this.chroma.clipBlack = 0.3;
      this.chroma.clipWhite = 1;
      this.chroma.mask = false;
      //挂载
      this.chroma.source = this.source;

      //亮度配置参数
      // bright.brightness = 1.05;
      // //挂载
      // bright.source = chroma;

      //重新格式化配置参数
      this.reformat.width = this.target.width;
      this.reformat.height = this.target.height;
      this.reformat.mode = "contain";
      //挂载
      this.reformat.source = this.chroma;

      //输出挂载
      this.target.source = this.reformat;

      setTimeout(() => {
        this.seriously.go();
      }, 10);
    },
    loadeddata(item) {
      let video1 = this.$refs.video1;
      if (item.clip === 0) {
        video1.currentTime = 0.01;
      } else {
        video1.currentTime = item.clip;
      }
    },
    timeupdate(item) {
      let video1 = this.$refs.video1;
      let startTime = item.clip;
      let endTime = item.clip + item.duration;

      if (video1.currentTime < startTime) {
        video1.currentTime = startTime;
      }

      if (video1.currentTime > endTime) {
        //video1.currentTime = endTime;
        video1.pause();
      }
    },
    // eslint-disable-next-line no-unused-vars
    controlPlay(item) {
      let video1 = this.$refs.video1;
      let startTime = item.clip;
      //let endTime = item.clip + item.duration;

      if (video1.paused) {
        video1.currentTime = startTime;
        video1.play();
      } else {
        video1.pause();
      }
    },
  },
  watch: {
    // "editor.currentSprite.gColor": {
    //   handler(newVal, oldVal) {
    //     console.log(newVal, oldVal);
    //     //this.initSeriously();
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    "item.width": {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal);
        //this.initSeriously();

        // let video1 = this.$refs.video1;
        // video1.pause();

        // this.seriously.stop();
        // this.reformat.width = this.item.width;
        // this.reformat.height = this.item.height;
        //this.seriously.stop();
        //this.seriously.destroy();
        //this.initSeriously();

        this.data1.green = false;

        setTimeout(() => {
          this.data1.green = true;
        }, 10);
      },
      immediate: false,
      deep: true,
    },
  },
};
</script>

<style scoped></style>

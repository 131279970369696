<template>
  <div :style="style1">
    <div v-html="text2html()"></div>
  </div>

  <!-- <div v-if="item.textEffect === 'none'" :style="style1" v-html="text2html()"></div> -->

  <!-- <div v-if="item.textEffect === 'title1'" class="title1">
    <div :class="'animate__animated animate__fadeInDown'">{{title1()}}</div>
  </div>

  <div v-if="item.textEffect === 'list1'" class="list1">
    <div v-for="(text,index) of list1()" :key="index"
         :class="'animate__animated animate__fadeInDown  ' + ' animate__delay-' + (index+1) + 's'"
    >{{text}}</div>
  </div>

  <div v-if="item.textEffect === 'list2'" class="list2">
    <div v-for="(text,index) of list2()" :key="index"
         :class="'animate__animated animate__flipInX  ' + ' animate__delay-' + (index+1) + 's'"
    >{{text}}</div>
  </div>

  <div v-if="item.textEffect === 'content1'" class="content1">
    <div v-for="(text,index) of content1()" :key="index"
         :class="'animate__animated animate__fadeInUp  ' + ' animate__delay-' + (index+1) + 's'"
    >{{text}}</div>
  </div> -->
</template>

<script>
export default {
  name: "textShow",
  props: {
    item: Object,
    style1: String,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    text2html() {
      //let html = this.item.text.replaceAll("\n", "<br/>\n");

      let arr = this.item.text.split("\n");

      if (arr.length > 0) {
        let html = "";
        arr.forEach((element) => {
          html += "<p>" + element + "</p>\n";
        });
        return html;
      } else {
        return this.item.text;
      }
    },
    title1() {
      return this.item.text;
    },
    list1() {
      let arr = this.item.text.split(/[\n]/);
      var filteredArr = arr.filter(function (el) {
        return el != "";
      });
      return filteredArr;
    },
    list2() {
      let arr = this.item.text.split(/[\n]/);
      var filteredArr = arr.filter(function (el) {
        return el != "";
      });
      return filteredArr;
    },
    content1() {
      let arr = this.item.text.split(/[\n]/);
      var filteredArr = arr.filter(function (el) {
        return el != "";
      });
      return filteredArr;
    },
  },
};
</script>

<style>
p {
  margin-block-start: 0;
  margin-block-end: 0;
}
</style>

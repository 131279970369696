<script>
import { mapState } from "vuex";
// import axios from "axios";
import _ from "lodash";
import pptxgen from "pptxgenjs";
// import html2canvas from "html2canvas";

import * as htmlToImage from "html-to-image";
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

export default {
  name: "genPptx2",
  components: {},
  props: {},
  data() {
    return {
      list: [],
      pefix: "https://resources.laihua.com/",
    };
  },
  mounted() {
    //this.lodaData();
    this.loop();
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
  },
  methods: {
    lodaData() {},
    delay(n) {
      return new Promise(function (resolve) {
        setTimeout(resolve, n * 1000);
      });
    },
    async gen1() {
      var that = this;
      //   html2canvas(document.querySelector("#huabu1")).then((canvas) => {
      //     //document.body.appendChild(canvas);
      //   });
      let pptx = new pptxgen();

      //顺序迭代
      for await (const sence of this.editor.meta.sences) {
        this.editor.currentSence = sence;
        await this.delay(2);

        let dataUrl = await htmlToImage
          .toPng(document.getElementById("huabu1"))
          .then(function (dataUrl) {
            let slide = pptx.addSlide();
            slide.addImage({ data: dataUrl, x: 0, y: 0, w: "100%", h: "100%" });

            return dataUrl;
          });

        console.log(dataUrl);
      }

      let fileName1 = this.editor.project.id + this.editor.project.name + ".pptx";
      pptx.writeFile({ fileName: fileName1 }).then(async (fileName) => {
        //console.log((parseInt(this.$route.params.id) + 1));
        //this.$router.push("/editor/" + (parseInt(this.$route.params.id) + 1));
        console.log(`created file: ${fileName}`);
        await that.delay(3);
        location.href = "/editor2/" + (parseInt(this.$route.params.id) + 1);
      });
    },
    async loop() {
      //
      console.log("=========gen ppt==============");
      //
      await this.delay(3);

      let sences = this.editor.meta.sences;
      _.forEach(sences, function (sence) {
        sence.sprites = _.dropWhile(sence.sprites, function (sprite) {
          return sprite.green == true;
        });

        _.forEach(sence.sprites, function (sprite) {
          if (sprite.turl) sprite.preview = sprite.turl;
        });
      });

      let removeEnd = [];
      //
      if (sences.length >= 3) {
        let removeStart = _.drop(sences);
        removeEnd = _.dropRight(removeStart);
        //console.log(removeEnd);
      }
      this.editor.meta.sences = removeEnd;

      await this.gen1();
    },
  },
};
</script>

<template>
  <div>
    <el-button type="primary" @click="gen1">生成ppt</el-button>
  </div>
</template>

<style scoped></style>

<template>
  <img
    :id="'ap' + sprite.id"
    :key="sprite.id"
    class="spriteMain"
    :style="computStyle()"
    ref="sprite1"
    :src="sprite.preview"
  />
</template>

<script>
import { mapState } from "vuex";
// import ainme from "animejs";
import gsap from "../../gsap/esm/all.js";

export default {
  name: "playImage",
  components: {},
  props: {
    sceneIndex: Number,
    spriteIndex: Number,
    sprite: Object,
  },
  data() {
    return {
      visible: false,
      rafId: 0,
      startTime: 0,
      endTime: 0,
      startStat: false,
      endStat: false,
    };
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    let that = this;
    console.log(
      "sprite mounted playVideo--- index:" +
        this.spriteIndex +
        "---duration:" +
        this.sprite.duration
    );
    //console.log(this.$refs.sprite1);

    // let effect = {
    //   targets: "#ap" + this.sprite.id,
    //   keyframes: [
    //     { opacity: 0, scale: 0.5 },
    //     { opacity: 1, scale: 1, duration: 0.3 },
    //     { opacity: 1, scale: 1.8, duration: this.sprite.duration - 0.3 },
    //   ],
    //   duration: this.sprite.duration,
    //   easing: "cubicBezier(0.250, 0.460, 0.450, 0.940)",
    //   delay: 0,
    //   autoplay: false,
    // };

    // if (this.sprite.enterEffect === "typeStr") {
    //   //
    // }

    //https://greensock.com/docs/v3/GSAP/gsap.fromTo()
    let fromVars = [
      { opacity: 0 },
      { opacity: 0, scale: 1.0 },
      { opacity: 0, scale: 1.1 },
      { opacity: 0, scale: 1.1 },
      { opacity: 0, scale: 1.1 },
    ];
    let toVars = [
      {
        keyframes: [
          { opacity: 1, duration: 0.3 },
          { opacity: 1, duration: this.sprite.duration - 0.3 },
        ],
        ease: "Power1.easeOut",
      },
      {
        keyframes: [
          { opacity: 1, scale: 1.0, duration: 0.2 },
          { opacity: 1, scale: 1.1, duration: this.sprite.duration - 0.2 },
        ],
        ease: "Power1.easeOut",
      },
      {
        keyframes: [
          { opacity: 1, scale: 1.1, duration: 0.2 },
          { opacity: 1, scale: 1.0, duration: this.sprite.duration - 0.2 },
        ],
        ease: "Power1.easeOut",
      },
      {
        keyframes: [
          { opacity: 1, scale: 1.1, duration: 0.2 },
          { opacity: 1, scale: 1.1, x: 50, duration: this.sprite.duration - 0.2 },
        ],
        ease: "Power1.easeOut",
      },
      {
        keyframes: [
          { opacity: 1, scale: 1.1, duration: 0.2 },
          { opacity: 1, scale: 1.1, y: 50, duration: this.sprite.duration - 0.2 },
        ],
        ease: "Power1.easeOut",
      },
    ];

    let _id = this.$route.params.id;
    let check = this.checkIdInRange(_id);
    if (check) {
      this.gsap1 = gsap.fromTo(
        "#ap" + this.sprite.id,
        fromVars[this.sceneIndex % 5],
        toVars[this.sceneIndex % 5]
      );
    } else {
      this.gsap1 = gsap.fromTo(
        "#ap" + this.sprite.id,
        { opacity: 0 },
        { opacity: 1, duration: 0.3 }
      );
    }

    this.gsap1.pause();
  },
  watch: {
    "sprite.runStatus": {
      handler: function (newVal, oldVal) {
        console.log(
          "watch sprite.runStatus---spriteIndex:" +
            this.spriteIndex +
            "---newVal:" +
            newVal +
            "---oldVal:" +
            oldVal
        );

        if (newVal == 2) {
          this.visible = true;

          //开始本精灵
          this.begin();
        }

        if (newVal == 4) {
          //this.visible = false;

          //结束本精灵
          this.stop();
        }

        if (newVal == 9) {
          this.visible = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    begin() {
      this.beginTime = window.performance.now();
      this.startTime = this.beginTime + this.sprite.start * 1000;
      this.endTime = this.startTime + this.sprite.duration * 1000;
      //raf循环
      this.rafRun1();
    },
    start() {
      //chrome提示错误是因为chrome不允许自动播放视频，需要先点击下页面触发
      //this.$refs.sprite1.play();
      //this.$refs.sprite1.classList.add("animate__animated", "animate__slideInUp");

      this.gsap1.play();
    },
    stop() {
      //退出循环
      this.endStat = true;

      //this.$refs.sprite1.pause();

      //退出动效
      // this.$refs.sprite1.classList.add("animate__animated");
      // this.$refs.sprite1.classList.add("animate__slideInUp");
    },
    rafRun1() {
      //判断开始
      if (window.performance.now() > this.startTime) {
        if (!this.startStat) {
          this.start();
          this.startStat = true;
        }
        this.rafId = requestAnimationFrame(this.rafRun2);
      } else {
        this.rafId = requestAnimationFrame(this.rafRun1);
      }
    },
    rafRun2() {
      //判断结束
      if (window.performance.now() > this.endTime) {
        cancelAnimationFrame(this.rafId);
        this.editor.meta.scenes[this.sceneIndex].sprites[this.spriteIndex].runStatus = 4;
      } else {
        this.rafId = requestAnimationFrame(this.rafRun2);
      }
    },
    computStyle() {
      let style = "";
      style += "z-index: " + (100 - this.spriteIndex) + ";";
      style += "position: absolute;";
      style += "left: " + this.sprite.left + "px;";
      style += "top: " + this.sprite.top + "px;";
      style += "width: " + this.sprite.width + "px;";
      style += "height: " + this.sprite.height + "px;";
      style += "opacity: " + "0;";
      //style += "border-radius: 20px;";
      //style += "box-shadow: 0px 2px 27px 6px rgba(204, 204, 204, 1);";

      // if (this.sprite.textEffect !== "fadeOut") {
      //   style += "opacity: " + "1;";
      // }

      //console.log(style);
      return style;
    },
    loadeddata() {
      this.$refs.sprite1.currentTime = this.sprite.clip;
    },
    checkIdInRange(id) {
      // 创 5877-6176
      // 大 5581-5870
      // 绿 5459-5578
      // 蔡 5001-5210
      // 秦 2481-2708
      // 孙 1354-1423

      let stat = false;

      if (id >= 2481 && id <= 2708) {
        return true;
      }
      if (id >= 5581 && id <= 5870) {
        return true;
      }
      if (id >= 5877 && id <= 6176) {
        return true;
      }

      return stat;
    },
  },
};
</script>

<style scoped>
.test1 {
  position: absolute;
  border-radius: 20px;
  box-shadow: 0px 2px 27px 6px rgba(204, 204, 204, 1);
}
.spriteHide {
  /* visibility: hidden; */
  opacity: 0;
}

.spriteShow {
  /* visibility: visible; */
  opacity: 1;
}
</style>

<template>
  <object ref="flash1"
          :id="'flash_' + item.id"
          type="application/x-shockwave-flash"
          :data="item.preview"
          :width="item.width"
          :height="item.height">
    <param name="movie" :value="item.preview" />
    <param name="play" value="false" />
    <param name="loop" value="false" />
    <param name="menu" value="true" />
    <param name="quality" value="high" />
    <param name="scale" value="showall" />
    <param name="align" value="t" />
    <param name="salign" value="t" />
    <param name="wmode" value="transparent" v-if="item.isTransparent"  />
    <param name="bgcolor" value="#ffffff" />
    <param name="allowFullScreen" value="true" />
    <param name="flashvars" value="play=false" />
    <param name="browserzoom" value="scale" />
  </object>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "flash",
  props:{
    item: Object
  },
  data(){
    return {
        local: this.item
    }
  },
  computed: {
    ...mapState({ msg: state => state.msg, editor: state => state.editor }),
  },
  mounted() {
    // setTimeout(()=>{
    //   let total = this.$refs.flash1.TotalFrames();
    //   let duration = total / 30;
    //
    //   this.local.duration = duration;
    // },2000);
  },

};
</script>

<style scoped>

</style>
<template>
  <object ref="sprite1"
          class="spriteMain"
          :class="visible ? 'spriteShow' : 'spriteHide'"
          :style="computStyle()"
          :key="sprite.id"
          :id="'flash_' + sprite.id"
          type="application/x-shockwave-flash"
          :data="sprite.preview"
          :width="sprite.width"
          :height="sprite.height">
    <param name="movie" :value="sprite.preview" />
    <param name="play" value="false" />
    <param name="loop" value="false" />
    <param name="menu" value="true" />
    <param name="quality" value="high" />
    <param name="scale" value="showall" />
    <param name="align" value="t" />
    <param name="salign" value="t" />
    <param name="wmode" value="transparent" v-if="sprite.isTransparent"  />
    <param name="bgcolor" value="#ffffff" />
    <param name="allowFullScreen" value="true" />
    <param name="flashvars" value="play=false" />
    <param name="browserzoom" value="scale" />
  </object>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "playFlash",
  components:{
  },
  props: {
    sceneIndex: Number,
    spriteIndex: Number,
    sprite: Object
  },
  data(){
    return {
      visible: false,
      rafId: 0,
      startTime: 0,
      endTime: 0,
      startStat: false
    }
  },
  computed: {
    ...mapState({ msg: state => state.msg, editor: state => state.editor }),
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    let that = this;
    console.log("sprite mounted playFlash--- index:" + this.spriteIndex + "---duration:" + this.sprite.duration);
    //console.log(this.$refs.sprite1);
  },
  watch: {
    "sprite.runStatus": {
      handler: function (newVal, oldVal) {
        console.log("watch sprite.runStatus---spriteIndex:" + this.spriteIndex + "---newVal:" + newVal + "---oldVal:" + oldVal);

        if(newVal == 2) {
          this.visible = true;

          //开始本精灵
          this.begin();
        }

        if(newVal == 4) {
          //this.visible = false;

          //结束本精灵
          this.stop();
        }

        if(newVal == 9) {
          this.visible = false;
        }
      },
      deep: true
    }
  },
  methods: {
    begin() {
      this.beginTime = window.performance.now();
      this.startTime = this.beginTime + this.sprite.start * 1000;
      this.endTime = this.startTime + this.sprite.duration * 1000;
      //raf循环
      this.rafRun1();
    },
    start(){
      //chrome提示错误是因为chrome不允许自动播放视频，需要先点击下页面触发
      this.$refs.sprite1.Play();
    },
    stop() {
      //退出循环
      this.startStat = true;

      //退出动效
      // this.$refs.scene1.classList.add("animate__animated");
      // this.$refs.scene1.classList.add("animate__fadeOut");
    },
    rafRun1() {
      //判断开始
      if (window.performance.now() > this.startTime) {
        if (!this.startStat){
          this.start();
          this.startStat = true;
        }
        this.rafId = requestAnimationFrame(this.rafRun2);
      } else {
        this.rafId = requestAnimationFrame(this.rafRun1);
      }
    },
    rafRun2() {
      //判断结束
      if (window.performance.now() > this.endTime) {
        cancelAnimationFrame(this.rafId);
        this.$refs.sprite1.StopPlay();
        this.editor.meta.scenes[this.sceneIndex].sprites[this.spriteIndex].runStatus = 4;
      } else {
        this.rafId = requestAnimationFrame(this.rafRun2);
      }
    },
    computStyle() {
      let style = "";
      style += "z-index: " + (100 - this.spriteIndex) + ";";
      style += "position: absolute;";
      style += "left: " + this.sprite.left + "px;";
      style += "top: " + this.sprite.top + "px;";
      style += "width: " + this.sprite.width + "px;";
      style += "height: " + this.sprite.height + "px;";

      //console.log(style);
      return style;
    },
    loadeddata() {
      this.$refs.sprite1.currentTime = this.sprite.clip;
    }
  }
};
</script>

<style scoped>
.test1{
  position: absolute;
}
.spriteHide {
  visibility: hidden;
}

.spriteShow {
  visibility: visible;
}

</style>

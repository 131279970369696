<script>
import { mapState } from "vuex";
import axios from "axios";
import xpath from "xpath";
import xmldom from "xmldom";
import _ from "lodash";
import { ElMessage } from "element-plus";

export default {
  name: "genCaptionImage",
  props: {
    gen: Object,
  },
  data() {
    return {
      imgList: [],
      data1: this.gen,
    };
  },
  mounted() {
    //
    //this.imgList = [{ url: "1111" }, { url: "1111" }];
    this.start();
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
  },
  methods: {
    start() {
      var that = this;

      let keyword = "";
      if (this.gen.keyword) {
        keyword = this.gen.keyword;
      } else if (this.gen.fenci) {
        keyword = this.gen.fenci;
      } else {
        keyword = this.gen.text;
      }

      axios
        .get("http://cn.bing.com/images/async", {
          params: {
            q: keyword,
          },
        })
        .then(function (response) {
          console.log("response.data=====");

          let dom = xmldom.DOMParser;
          let xml = response.data;
          let doc = new dom().parseFromString(xml);
          let nodes = xpath.select("//div[@class='iuscp isv']", doc);

          //let title = xpath.select("/div[1]/div[1]/a", nodes[0]);
          //console.log(nodes[0].localName + ": " + nodes[0].firstChild.data);

          //let n1 = xpath.select("//a[@m]", doc);  childNodes
          //let n2 = xpath.select("//div[@class='img_info hon']/span[@class='nowrap']", doc);
          //   console.log(nodes[0]);
          //   console.log(nodes[0].firstChild.firstChild.attributes[2].nodeValue);
          //   console.log(nodes[0].firstChild.childNodes[1].firstChild.firstChild.nodeValue);

          nodes = _.take(nodes, 10);
          //let imgArr = [];

          nodes.forEach((element) => {
            let m = JSON.parse(element.firstChild.firstChild.attributes[2].nodeValue);
            //console.log("m", m);
            let arr1 = element.firstChild.childNodes[1].firstChild.firstChild.nodeValue;
            let arr2 = arr1.replace(/ /g, "");
            arr2 = _.replace(arr2, "·", "x");
            //console.log("arr2", arr2);
            let width = _.split(arr2, "x")[0];
            let height = _.split(arr2, "x")[1];
            let ext = _.split(arr2, "x")[2];
            let newObj = {
              t: m.t,
              turl: m.turl,
              murl: m.murl,
              width: parseInt(width),
              height: parseInt(height),
              imageWidth: parseInt(width),
              imageHeight: parseInt(height),
              ext: ext.replace("jpeg", "jpg"),
            };
            that.imgList.push(newObj);
          });

          //   data.push(imgArr[0]);
          //   console.log("gen", data);

          // imgArr.forEach((element) => {
          //   data1.push(element);
          // });

          // console.log("imgArr", data1);
          // console.log("data", data1);
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // 总是会执行
        });
    },
    selectImg1(item) {
      this.data1.imageWebPath = item.turl;
      this.data1.imageWidth = item.width;
      this.data1.imageHeight = item.height;
      this.data1.ext = item.ext;
      this.data1.name = item.t;
      this.data1.turl = item.turl;
      this.data1.murl = item.murl;

      let stat = this.isExistFile(item.murl);
      if (!stat) {
        this.data1.imageWebPath = "";
        this.data1.turl = "";
        this.data1.murl = "";
      }
    },
    isExistFile(url) {
      var stat = false;
      var xmlhttp = new XMLHttpRequest();
      xmlhttp.open("GET", url, false);
      xmlhttp.send();
      if (xmlhttp.readyState == 4) {
        if (xmlhttp.status == 200) stat = true;
        //url存在
      }
      if (!stat) {
        ElMessage({ message: "远端大图不存在，请重选一个", type: "danger" });
      }

      return stat;
    },
  },
};
</script>

<template>
  <div class="pt_container">
    <div class="gen1" v-for="(item, index) of imgList" :key="index">
      <img class="img" :src="item.turl" @click="selectImg1(item)" />
      <span>{{ item.width }}x{{ item.height }}</span>
    </div>
  </div>
</template>

<style>
.pt_container {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  height: 130px;
}
.img {
  width: 160px;
  height: 90px;
}
.gen1 {
  border: 1px solid #f00;
  display: inline-block;
  width: 160px;
}
</style>

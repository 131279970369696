<script>
import { mapState } from "vuex";
import { ElMessage } from "element-plus";
import ObjectID from "bson-objectid";
import axios from "axios";
import _ from "lodash";

import genCaptionImage from "./genCaptionImage.vue";

import lottieResource from "./resource/lottieResource";
import bgResource from "./resource/bgResource";
import resResource from "./resource/resResource";
import textResource from "./resource/textResource";
import musicResource from "./resource/musicResource";

export default {
  name: "button1",
  components: {
    genCaptionImage,
    lottieResource,
    bgResource,
    resResource,
    textResource,
    musicResource,
  },
  data() {
    return {
      mobanVisible: false,
      jueseVisible: false,
      vmbanVisible: false,
      peiyinVisible: false,
      peituVisible: false,
      bgList: [
        { type: "image", url: "/images/bg04.png" },
        { type: "image", url: "/images/bg01.png" },
        { type: "image", url: "/images/bg03.png" },
        { type: "image", url: "/images/bg02.png" },
        { type: "video", url: "/resource/bg/bg11.mp4" },
        { type: "video", url: "/resource/bg/bg12.mp4" },
        { type: "video", url: "/resource/bg/bg14.mp4" },
        { type: "video", url: "/resource/bg/bg15.mp4" },
        { type: "video", url: "/resource/bg/bg16.mp4" },
        { type: "video", url: "/resource/bg/bg17.mp4" },
        { type: "video", url: "/resource/bg/bg18.mp4" },
        { type: "video", url: "/resource/bg/bg20.mp4" },
      ],
      jiaoben1: "",
      jiaobenList: [],
      jiaobenList2: [],
      sckVisible: false,
    };
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
  },
  methods: {
    msglv() {
      ElMessage({ message: "请选中绿幕视频后，点击右侧的绿幕抠像按钮。", type: "success" });
    },
    msgzm() {
      ElMessage({ message: "请看右侧属性区，将多段文字放入后选择效果。", type: "success" });
    },
    msgdx() {
      ElMessage({ message: "请看右侧属性区，切换全局背景后后选择动效。", type: "success" });
    },
    msgpy() {
      ElMessage({ message: "语音服务器通信中，速度较慢。。。", type: "warnning" });
    },
    addSprite1(url) {
      var new1 = {
        name: "svg1",
        type: "image",
        path: url,
        preview: url,
        width: 640,
        height: 360,
        size: 1111,
        duration: 3,
        id: ObjectID().toHexString(),
        left: 0,
        top: 0,
        clip: 0,
        muted: false,
        active: false,
      };

      this.editor.currentSence.sprites.push(new1);
      //this.movePastePosition();
      this.jueseVisible = false;
    },
    addSprite2(url) {
      var new1 = {
        name: "svg2",
        type: "image",
        path: url,
        preview: url,
        width: 1280,
        height: 720,
        size: 1111,
        duration: 3,
        id: ObjectID().toHexString(),
        left: 0,
        top: 0,
        clip: 0,
        muted: false,
        active: false,
      };

      this.editor.currentSence.sprites.push(new1);
      //this.movePastePosition();
      this.mobanVisible = false;
    },
    vmbSelect(url) {
      this.editor.meta.backgroundImage = url;
      this.vmbanVisible = false;
    },
    jiaobenSplit() {
      let arr = _.split(this.jiaoben1, "\n");
      let arr2 = _.compact(arr);
      console.log(arr2);

      let arr3 = [];
      for (let i = 0; i < arr2.length; i++) {
        let obj = {
          text: arr2[i],
        };

        arr3.push(obj);
      }

      this.jiaobenList = arr3;
    },
    async createVoice() {
      //顺序迭代
      for await (const x of this.jiaobenList) {
        let { data } = await axios.post("/api/tts/GenVoice?text=" + x.text);
        console.log(data.result.text);
        x.webPath = data.result.webPath;
        x.duration = data.result.duration;
        x.fenci = await this.genFenci(x.text);
        x.keyword = await this.genKeyword(x.text);
      }
    },
    async genFenci(text) {
      let { data } = await axios.post("/api/tts/GenFenci?text=" + text);
      return data.result.fenci;
    },
    async genKeyword(text) {
      let { data } = await axios.post("/api/tts/GenKeyword?text=" + text);
      return data.result.keyword;
    },
    async createImage() {
      //顺序迭代
      for await (const x of this.jiaobenList) {
        //let keyword = _.split(x.keyword, ",")[0];
        let repStr = _.replace(x.keyword, ",", "");
        let imageInfoList = await this.getBingImageList(repStr);

        // imageInfoList = _.filter(imageInfoList, function (o) {
        //   return o.imageWidth > 1000;
        // });
        // console.log("imageInfoList " + imageInfoList.length);

        let rNum = _.random(1, 4);
        let imageInfo = imageInfoList[rNum];
        // let imageDown = await this.getBingImage(
        //   imageInfo.title,
        //   imageInfo.murl,
        //   imageInfo.ext,
        //   imageInfo.width,
        //   imageInfo.height
        // );

        // x.imageTitle = imageDown.title;
        // x.imageWebPath = imageDown.webPath;
        // x.imageExt = imageDown.ext;
        // x.imageWidth = imageDown.width;
        // x.imageHeight = imageDown.height;

        x.imageTitle = imageInfo.title;
        x.imageWebPath = imageInfo.turl;
        x.imageExt = imageInfo.ext;
        x.imageWidth = imageInfo.width;
        x.imageHeight = imageInfo.height;
      }
    },
    async getBingImageList(text) {
      let { data } = await axios.post("/api/tts/GetBingImageList?keyword=" + text);
      return data.result;
    },
    async getBingImage(title, url, ext, width, height) {
      let { data } = await axios.post(
        "/api/tts/GetBingImage?title=" +
          title +
          "&url=" +
          url +
          "&ext=" +
          ext +
          "&width=" +
          width +
          "&height=" +
          height
      );
      return data.result;
    },
    async updateImage(x) {
      //
      let repStr = _.replace(x.keyword, ",", "");
      let imageInfoList = await this.getBingImageList(repStr);

      console.log(imageInfoList);

      let rNum = _.random(1, 8);
      let imageInfo = imageInfoList[rNum];
      let imageDown = await this.getBingImage(
        imageInfo.title,
        imageInfo.murl,
        imageInfo.ext,
        imageInfo.width,
        imageInfo.height
      );

      x.imageTitle = imageDown.title;
      x.imageWebPath = imageDown.webPath;
      x.imageExt = imageDown.ext;
      x.imageWidth = imageDown.width;
      x.imageHeight = imageDown.height;
    },
    createScene() {
      this.jiaobenList.forEach((x) => {
        //console.log(x);
        let id = ObjectID().toHexString();
        let sence = {
          id: id,
          name: "sence1",
          sprites: [],
          note: x.text,
          keyword: x.keyword,
          fenci: x.fenci,
        };

        var voice1 = {
          name: "voice1",
          type: "audio",
          path: x.webPath,
          preview: x.webPath,
          width: 200,
          height: 50,
          size: 1111,
          duration: x.duration,
          id: ObjectID().toHexString(),
          left: 0,
          top: 0,
          start: 0,
          clip: 0,
          muted: false,
          active: false,
        };

        function newWH(w, h) {
          let newW, newH, left, top;
          if (w > h) {
            newW = 1280;
            newH = (1280 * h) / w;
            left = 0;
            top = (720 - newH) / 2;
          } else {
            newH = 720;
            newW = (720 * w) / h;
            top = 0;
            left = (1280 - newW) / 2;
          }

          return {
            newW,
            newH,
            left,
            top,
          };
        }

        let newPos = newWH(x.imageWidth, x.imageHeight);

        var image1 = {
          name: "image1",
          type: "image",
          path: x.imageWebPath,
          preview: x.imageWebPath,
          width: newPos.newW,
          height: newPos.newH,
          size: 1111,
          duration: 3,
          id: ObjectID().toHexString(),
          left: newPos.left,
          top: newPos.top,
          start: 0,
          clip: 0,
          muted: false,
          active: false,
          turl: x.turl,
          murl: x.murl,
        };

        sence.sprites.push(voice1);
        sence.sprites.push(image1);

        this.editor.meta.sences.push(sence);
      });
    },
    async updateImage2() {
      let x = this.editor.currentSence;
      let repStr = _.replace(x.keyword, ",", "");
      let imageInfoList = await this.getBingImageList(repStr);

      console.log(imageInfoList);

      let rNum = _.random(1, 8);
      let imageInfo = imageInfoList[rNum];
      let imageDown = await this.getBingImage(
        imageInfo.title,
        imageInfo.murl,
        imageInfo.ext,
        imageInfo.width,
        imageInfo.height
      );

      x.imageTitle = imageDown.title;
      x.imageWebPath = imageDown.webPath;
      x.imageExt = imageDown.ext;
      x.imageWidth = imageDown.width;
      x.imageHeight = imageDown.height;

      function newWH(w, h) {
        let newW, newH, left, top;
        if (w > h) {
          newW = 1280;
          newH = (1280 * h) / w;
          left = 0;
          top = (720 - newH) / 2;
        } else {
          newH = 720;
          newW = (720 * w) / h;
          top = 0;
          left = (1280 - newW) / 2;
        }

        return {
          newW,
          newH,
          left,
          top,
        };
      }

      let newPos = newWH(x.imageWidth, x.imageHeight);

      var image1 = {
        name: "image1",
        type: "image",
        path: x.imageWebPath,
        preview: x.imageWebPath,
        width: newPos.newW,
        height: newPos.newH,
        size: 1111,
        duration: 3,
        id: ObjectID().toHexString(),
        left: newPos.left,
        top: newPos.top,
        start: 0,
        clip: 0,
        muted: false,
        active: false,
        turl: x.turl,
        murl: x.murl,
      };

      x.sprites.push(image1);
    },
    insertVoice(x) {
      var voice1 = {
        name: "voice1",
        type: "audio",
        path: x.webPath,
        preview: x.webPath,
        width: 200,
        height: 50,
        size: 1111,
        duration: x.duration,
        id: ObjectID().toHexString(),
        left: -50,
        top: 0,
        clip: 0,
        muted: false,
        active: false,
      };

      this.editor.currentSence.sprites.push(voice1);
      this.peiyinVisible = false;
    },
    peituStart() {
      this.jiaobenList2 = [];
      this.peituVisible = true;
      let sences = this.editor.meta.sences;
      for (let i = 0; i < sences.length; i++) {
        let newObj = {
          senceIndex: i,
          fenci: sences[i].fenci,
          keyword: sences[i].keyword,
          text: sences[i].note,
        };
        if (sences[i].note) {
          this.jiaobenList2.push(newObj);
        }
      }
    },
    createImage2Scene() {
      //
      let sences = this.editor.meta.sences;

      function newWH(w, h) {
        let newW, newH, left, top;
        if (w > h) {
          newW = 1280;
          newH = (1280 * h) / w;
          left = 0;
          top = (720 - newH) / 2;
        } else {
          newH = 720;
          newW = (720 * w) / h;
          top = 0;
          left = (1280 - newW) / 2;
        }

        return {
          newW,
          newH,
          left,
          top,
        };
      }

      _.forEach(this.jiaobenList2, function (x) {
        let newPos = newWH(x.imageWidth, x.imageHeight);

        var image1 = {
          name: x.name,
          type: "image",
          path: x.murl,
          preview: x.murl,
          width: newPos.newW,
          height: newPos.newH,
          size: 1111,
          duration: 3,
          id: ObjectID().toHexString(),
          left: newPos.left,
          top: newPos.top,
          start: 0,
          clip: 0,
          muted: false,
          active: false,
          turl: x.turl,
          murl: x.murl,
        };

        sences[x.senceIndex].sprites.push(image1);
      });

      this.peituVisible = false;
    },
  },
};
</script>

<template>
  <div
    style="
      position: absolute;
      margin-bottom: 10px;
      z-index: 99;
      width: 1280px;
      height: 70px;
      top: 740px;
      /* left: 300px; */
      /* border: aquamarine 1px solid; */
    "
  >
    <el-button type="warning" icon="el-icon-search" @click="sckVisible = true"
      >打开素材库</el-button
    >
    <el-dialog
      v-model="sckVisible"
      title="素材库"
      width="80%"
      append-to-body="true"
      :close-on-click-modal="false"
    >
      <el-tabs :tab-position="tabPosition">
        <el-tab-pane label="角色">
          <lottieResource></lottieResource>
        </el-tab-pane>
        <el-tab-pane label="背景">
          <bgResource></bgResource>
        </el-tab-pane>
        <el-tab-pane label="素材">
          <resResource></resResource>
        </el-tab-pane>
        <el-tab-pane label="文字">
          <textResource></textResource>
        </el-tab-pane>

        <el-tab-pane label="声音">
          <musicResource></musicResource>
        </el-tab-pane>
      </el-tabs>
      <template #footer>
        <span class="dialog-footer" style="text-align: left">
          <el-button type="denger" @click="sckVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- <el-button type="warning" icon="el-icon-search" @click="mobanVisible = true"
      >插入模板</el-button
    > -->
    <el-dialog v-model="mobanVisible" title="插入模板" width="70%" append-to-body="true">
      <div>
        <img
          src="/resource/svg/bg01.svg"
          @click="addSprite2('/resource/svg/bg01.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/bg02.svg"
          @click="addSprite2('/resource/svg/bg02.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/bg03.svg"
          @click="addSprite2('/resource/svg/bg03.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/bg04.svg"
          @click="addSprite2('/resource/svg/bg04.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/bg05.svg"
          @click="addSprite2('/resource/svg/bg05.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/bg06.svg"
          @click="addSprite2('/resource/svg/bg06.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/bg07.svg"
          @click="addSprite2('/resource/svg/bg07.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/bg08.svg"
          @click="addSprite2('/resource/svg/bg08.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/bg09.svg"
          @click="addSprite2('/resource/svg/bg09.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/bg10.svg"
          @click="addSprite2('/resource/svg/bg10.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/bg11.svg"
          @click="addSprite2('/resource/svg/bg11.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/bg12.svg"
          @click="addSprite2('/resource/svg/bg12.svg')"
          style="width: 320px; height: 180px"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="mobanVisible = false">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- <el-button type="warning" icon="el-icon-search" @click="vmbanVisible = true"
      >插入视频模板</el-button
    > -->
    <el-dialog v-model="vmbanVisible" title="插入视频模板" width="80%" append-to-body="true">
      <div>
        <div
          v-for="(item, index) of bgList"
          :key="index"
          style="display: inline-flex; padding: 5px"
        >
          <img
            v-if="item.type === 'image'"
            style="width: 320px; height: 180px"
            :class="editor.meta.backgroundImage == item.url ? 'actived' : ''"
            @click="vmbSelect(item.url)"
            :src="item.url"
          />
          <video
            v-if="item.type === 'video'"
            style="width: 320px; height: 180px"
            :class="editor.meta.backgroundImage == item.url ? 'actived' : ''"
            @click="vmbSelect(item.url)"
            :src="item.url"
          ></video>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="vmbanVisible = false">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- <el-button type="warning" icon="el-icon-search" @click="jueseVisible = true"
      >插入角色</el-button
    > -->
    <el-dialog v-model="jueseVisible" title="插入角色" width="70%" append-to-body="true">
      <div>
        <img
          src="/resource/svg/ma11.svg"
          @click="addSprite1('/resource/svg/ma11.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/ma12.svg"
          @click="addSprite1('/resource/svg/ma12.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/ma13.svg"
          @click="addSprite1('/resource/svg/ma13.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/ma14.svg"
          @click="addSprite1('/resource/svg/ma14.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/wm21.svg"
          @click="addSprite1('/resource/svg/wm21.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/wm22.svg"
          @click="addSprite1('/resource/svg/wm22.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/wm23.svg"
          @click="addSprite1('/resource/svg/wm23.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/wm24.svg"
          @click="addSprite1('/resource/svg/wm24.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/wm11.svg"
          @click="addSprite1('/resource/svg/wm11.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/wm12.svg"
          @click="addSprite1('/resource/svg/wm12.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/wm13.svg"
          @click="addSprite1('/resource/svg/wm13.svg')"
          style="width: 320px; height: 180px"
        />
        <img
          src="/resource/svg/wm14.svg"
          @click="addSprite1('/resource/svg/wm14.svg')"
          style="width: 320px; height: 180px"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="jueseVisible = false">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-button type="primary" icon="el-icon-search" @click="peiyinVisible = true"
      >智能配音</el-button
    >
    <el-dialog
      v-model="peiyinVisible"
      title="智能配音"
      width="80%"
      append-to-body="true"
      :close-on-click-modal="false"
    >
      <div>
        <div>多行脚本</div>
        <textarea
          v-model="jiaoben1"
          @change="jiaobenSplit"
          style="width: 50%; height: 100px"
        ></textarea>
        <div>
          <div v-for="(item, index) of jiaobenList" :key="index">
            <div>脚本：{{ item.text }}</div>
            <div v-if="item.fenci">分词：{{ item.fenci }}</div>
            <div v-if="item.keyword">提取关键词：{{ item.keyword }}</div>
            <audio v-if="item.webPath" controls :src="item.webPath"></audio>
            <el-button v-if="item.webPath" type="primary" @click="insertVoice(item)"
              >将此条配音插入当前场景</el-button
            >
            <el-button type="primary" @click="updateImage(item)">重新配图</el-button>
            <img
              v-if="item.imageWebPath"
              :src="item.imageWebPath"
              style="width: auto; height: 200px; cursor: pointer"
              @click="updateImage(item)"
            />

            <genCaptopnImageVue :gen="item"></genCaptopnImageVue>

            <span v-if="item.imageWebPath">{{ item.imageWidth + " x " + item.imageHeight }}</span>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer" style="text-align: left">
          <el-button type="primary" @click="createVoice()">开始配音</el-button>
          <el-button type="primary" @click="createImage()">开始配图</el-button>
          <el-button type="primary" @click="createScene()">全部创建为场景</el-button>

          <el-button type="denger" @click="peiyinVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

    <el-button type="primary" icon="el-icon-search" @click="peituStart">智能配图</el-button>
    <el-dialog
      v-model="peituVisible"
      title="智能配图"
      width="90%"
      append-to-body="true"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <div>
        <div>
          <div v-for="(item, index) of jiaobenList2" :key="index">
            <div v-if="item.text">字幕：{{ item.text }}</div>
            <div v-if="item.fenci">分词：{{ item.fenci }}</div>
            <div v-if="item.keyword">关键词：{{ item.keyword }}</div>
            <img
              v-if="item.imageWebPath"
              :src="item.imageWebPath"
              style="width: auto; height: 200px; cursor: pointer"
            />
            <span v-if="item.imageWebPath">{{ item.imageWidth + " x " + item.imageHeight }}</span>
            <genCaptionImage :gen="item"></genCaptionImage>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer" style="text-align: left">
          <el-button type="primary" @click="createImage2Scene">将选定的图创建到场景内</el-button>

          <!-- <el-button type="denger" @click="peituVisible = false">关闭</el-button> -->
        </span>
      </template>
    </el-dialog>

    <el-button type="info" icon="el-icon-search" @click="updateImage2">智能放图</el-button>
    <el-button type="info" icon="el-icon-search" @click="msgdx">选择动效</el-button>
    <el-button type="info" icon="el-icon-search" @click="msgzm">智能字幕</el-button>
    <el-button type="success" icon="el-icon-search" @click="msglv">绿幕抠像</el-button>
  </div>
</template>

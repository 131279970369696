<template>
  <!-- no green -->
  <video
    :id="'vd' + item.id"
    ref="video1"
    :src="item.preview"
    :width="item.width"
    :height="item.height"
    :muted="item.muted"
    controls
    @loadeddata="loadeddata(item)"
    @click.prevent="controlPlay(item)"
  ></video>
</template>

<script>
export default {
  name: "videoShow",
  props: {
    item: Object,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    loadeddata(item) {
      let video1 = this.$refs.video1;
      if (item.clip === 0) {
        video1.currentTime = 0.01;
      } else {
        video1.currentTime = item.clip;
      }
    },
    // eslint-disable-next-line no-unused-vars
    controlPlay(item) {
      let video1 = this.$refs.video1;
      // if (video1.paused) {
      //   video1.currentTime = item.clip;
      //   video1.play();
      // } else {
      //   video1.pause();
      // }
      video1.play();
    },
  },
  watch: {
    "item.clip": function (newVal) {
      console.log(newVal);
      let video1 = this.$refs.video1;
      video1.currentTime = newVal;
    },
  },
};
</script>

<style scoped></style>

<template>
  <div style="overflow-x: scroll; height: 90px">
    <div
      id="tl_ct_001"
      style="position: relative; height: 30px; padding-top: 5px"
      :style="'width:' + spriteWidth + 'px'"
    ></div>
    <div
      style="background-color: #e0e0e0; position: relative; height: 40px"
      :style="'width:' + spriteWidth + 'px'"
    >
      <Vue3DraggableResizable
        v-if="editor.currentSprite.id"
        :parent="true"
        :lockAspectRatio="false"
        :initW="compWidth"
        :initH="40"
        :y="0"
        :h="40"
        :draggable="true"
        :resizable="true"
        :disabledY="true"
        :handles="['mr', 'ml']"
        style="background-color: rgb(38, 129, 110)"
        v-model:x="compStart"
        v-model:w="compWidth"
      ></Vue3DraggableResizable>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Vue3DraggableResizable from "vue3-draggable-resizable";
//需引入默认样式
import "vue3-draggable-resizable/dist/Vue3DraggableResizable.css";

import Ruler from "@scena/ruler";

export default {
  name: "timeline",
  components: {
    Vue3DraggableResizable,
  },
  data() {
    return {
      currentTotal: 0,
      currentStart: 0,
      currentDuration: 0,
      kedu: 50,
      spriteWidth: 50 * 300,
    };
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
    //
    compTotal: function () {
      let total = 3;

      if (this.editor.currentSence.sprites) {
        this.editor.currentSence.sprites.forEach((item) => {
          let compTotal = item.start + item.duration;
          if (compTotal > total) {
            total = compTotal;
          }
        });
      }

      return total;
    },
    compStart: {
      get: function () {
        return this.editor.currentSprite?.start * this.kedu;
      },
      // eslint-disable-next-line no-unused-vars
      set: function (v) {
        this.editor.currentSprite.start = v / this.kedu;
      },
    },
    compWidth: {
      get: function () {
        return this.editor.currentSprite?.duration * this.kedu;
      },
      // eslint-disable-next-line no-unused-vars
      set: function (v) {
        this.editor.currentSprite.duration = v / this.kedu;
      },
    },
  },
  mounted() {
    this.ruler = new Ruler(document.querySelector("#tl_ct_001"), {
      type: "horizontal",
      backgroundColor: "#fff",
      lineColor: "#ddd",
      textColor: "#000",
      textFormat: (string) => string / this.kedu,
    });

    // document.querySelector("#tl_ct_001").addEventListener("resize", () => {
    //   this.ruler.resize();
    // });
  },
  methods: {},
  watch: {
    compWidth: {
      // eslint-disable-next-line no-unused-vars
      handler(newName, oldName) {
        var that = this;
        let total =
          (this.editor.currentSprite?.start + this.editor.currentSprite?.duration) * this.kedu;
        if (total > this.spriteWidth) {
          this.spriteWidth = total;
        } else {
          this.spriteWidth = 50 * 300;
        }

        setTimeout(() => {
          that.ruler.resize();
        }, 0);
      },
      immediate: false,
    },
  },
};
</script>

<style scoped></style>

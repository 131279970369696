<template>
  <div class="playContainer">
    <div class="globalBackGround">
      <audio src="/images/-100db.mp3" autoplay loop></audio>
      <audio :src="bgMusic" id="bg_handler_audio_01" loop></audio>

      <!-- <img
        v-if="bgTypeCheck(editor.meta.backgroundImage) === 'png'"
        style="width: 1280px; height: 720px"
        :src="editor.meta.backgroundImage"
      />
      <video
        v-if="bgTypeCheck(editor.meta.backgroundImage) === 'mp4'"
        style="width: 1280px; height: 720px"
        :src="editor.meta.backgroundImage"
        autoplay
        loop
        muted
      ></video> -->

      <!-- <div v-if="editor.meta.backgroundImage" style="position: absolute">
        <img
          v-if="bgTypeCheck(editor.meta.backgroundImage) === 'jpg'"
          style="width: 1280px; height: 720px"
          :src="editor.meta.backgroundImage"
        />
        <video
          v-if="bgTypeCheck(editor.meta.backgroundImage) === 'mp4'"
          style="width: 1280px; height: 720px"
          :src="editor.meta.backgroundImage"
          autoplay
          loop
          muted
        ></video>
      </div> -->
    </div>

    <div v-for="(scene, index) of editor.meta.scenes" :key="scene.id">
      <playScene
        v-if="scene.runStatus > 0 && scene.runStatus < 9"
        :sceneIndex="index"
        :scene="scene"
      ></playScene>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import playScene from "@/components/player/planScene";
import _ from "lodash";

export default {
  name: "container",
  components: {
    playScene,
  },
  props: {
    id: String,
  },
  data() {
    return {
      totalSeconds: 0,
      bgMusic: "https://resources.laihua.com/2021-03-02/48e12740-7b0b-11eb-bdcb-acde48001122.mp3",
    };
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
  },
  mounted() {
    //绑定CefSharp注册的对象
    setTimeout(() => {
      (async function () {
        // eslint-disable-next-line no-undef
        await CefSharp.BindObjectAsync("callbackObj");
      })();
    }, 0);

    //切换背景音乐
    // let bga = document.getElementById("bg_handler_audio_01");
    // if (this.$route.params.id > 200) {
    //   bga.src = "/images/bg02.mp3";
    // }

    // bga.addEventListener("loadeddata", function () {
    //   bga.pause();
    //   bga.volume = 0.0;
    //   //bga.play();
    // });

    let that = this;

    //this.makeBgWhiteNoise();

    this.loadData();

    //太短了不行，第一帧可能加载不完
    setTimeout(() => {
      that.startMotion();
    }, 6000);
  },
  methods: {
    loadData() {
      let _id = this.id;
      let that = this;

      axios.get("/api/project/getsingle/" + _id).then(function (response) {
        let _data = response.data.result;
        let _meta = JSON.parse(_data.meta.replaceAll("sences", "scenes"));
        console.log(_meta);

        that.editor.meta = that.reduMeta(_meta);
        that.editor.meta.name = _data.name;

        if (that.editor.meta.bgMusic) {
          that.bgMusic = that.editor.meta.bgMusic;
        }

        that.redoGenProject(parseInt(_id));
      });
    },
    reduMeta(_meta) {
      let that = this;

      if (_meta.exitEffect === undefined) {
        _meta.exitEffect = "none";
      }

      _meta.scenes.forEach((x) => {
        //计算场景时长，增加场景状态
        x.duration = 3;
        x.runStatus = 0;

        x.sprites.forEach((y) => {
          y.runStatus = 0;

          //重整参数1
          if (y.green === undefined) {
            y.green = false;
          }
          //重整参数2
          if (y.start === undefined) {
            y.start = 0;
          }

          //找出最大精灵时长
          let yDuration = y.start + y.duration;

          if (x.duration < yDuration) {
            x.duration = yDuration;
          }

          //
        });

        //

        //计算总时长
        that.totalSeconds += x.duration;
        console.log(
          "current.seconds: " + x.duration + "----that.totalSeconds: " + that.totalSeconds
        );
      });

      return _meta;
    },
    startMotion() {
      let that = this;

      //0 预留
      //1 渲染，不显
      //2 开始，显示
      //3 运行中
      //4 结束，加载退出效果
      //9 移除dom

      //预加载场景1
      that.editor.meta.scenes[0].runStatus = 1;
      //预加载场景内的精灵
      this.editor.meta.scenes[0].sprites.forEach((x) => {
        x.runStatus = 1;
      });

      //显示场景1
      setTimeout(() => {
        that.editor.meta.scenes[0].runStatus = 2;
      }, 2500);
    },

    bgTypeCheck(bgUrl) {
      if (_.includes(bgUrl, "mp4")) {
        return "mp4";
      }

      return "jpg";
    },

    makeBgWhiteNoise() {
      //
      var audioCtx = new (window.AudioContext || window.webkitAudioContext)();

      // 立体声
      var channels = 1;
      // 创建一个 采样率与音频环境 (AudioContext) 相同的 时长 2 秒的 音频片段。
      var frameCount = audioCtx.sampleRate * 2.0;

      var myArrayBuffer = audioCtx.createBuffer(channels, frameCount, audioCtx.sampleRate);

      // 使用白噪声填充;
      // 就是 -1.0 到 1.0 之间的随机数
      for (var channel = 0; channel < channels; channel++) {
        // 这允许我们读取实际音频片段 (AudioBuffer) 中包含的数据
        var nowBuffering = myArrayBuffer.getChannelData(channel);
        for (var i = 0; i < frameCount; i++) {
          // Math.random() is in [0; 1.0]
          // audio needs to be in [-1.0; 1.0]
          nowBuffering[i] = Math.random() * 0.002 - 1;
        }
      }

      // 获取一个 音频片段源节点 (AudioBufferSourceNode)。
      // 当我们想播放音频片段时，我们会用到这个源节点。
      var source = audioCtx.createBufferSource();
      // 把刚才生成的片段加入到 音频片段源节点 (AudioBufferSourceNode)。
      source.buffer = myArrayBuffer;
      // 把 音频片段源节点 (AudioBufferSourceNode) 连接到
      // 音频环境 (AudioContext) 的终节点，这样我们就能听到声音了。
      source.connect(audioCtx.destination);

      source.loop = true;
      // 开始播放声源
      source.start();
    },
    checkIdInRange(id) {
      // 创 5877-6176
      // 大 5581-5870
      // 绿 5459-5578
      // 蔡 5001-5210
      // 秦 2481-2708
      // 孙 1354-1423

      let stat = false;

      if (id >= 2481 && id <= 2708) {
        return true;
      }
      if (id >= 5581 && id <= 5870) {
        return true;
      }
      if (id >= 5877 && id <= 6176) {
        return true;
      }

      return stat;
    },
    redoGenProject(id) {
      let check = this.checkIdInRange(id);
      console.log("##########", id, check);

      if (check) {
        //
        console.log("checkIdInRange");

        let scenes = this.editor.meta.scenes;

        _.forEach(scenes, function (scene) {
          //console.log(scene);
          let sprites = scene.sprites;

          let audioSprite = _.find(sprites, function (sprite) {
            return sprite.type == "audio";
          });
          console.log(audioSprite);

          if (audioSprite) {
            _.forEach(sprites, function (sprite) {
              if (sprite.type == "image") {
                sprite.duration = audioSprite.duration;
              }
            });
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.playContainer {
  width: 1280px;
  height: 720px;
  position: relative;
  /*background-color: #ffffff;*/
  overflow: hidden;
}
.globalBackGround {
  z-index: 10;
  width: 1280px;
  height: 720px;
  left: 0px;
  top: 0px;
  position: absolute;
  /* background-image: url("/images/bg01.png"); */
  background-size: cover;
}
</style>
